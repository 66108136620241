import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { Layout } from '../views/Layout';
import { Service } from '../services';
import { Partner } from '../config/AppConfig';

const AuthContext = React.createContext({
  authorized: 0,
  menu: null,
  menuItems: [],
  onLogin: () => { },
  onLogout: () => { },
});

const AuthConsumer = AuthContext.Consumer;

class AuthProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      authorized: 0,
      menu: null,
      menuItems: [],
    };

    this.onLogin = this.onLogin.bind(this);
    this.onLogout = this.onLogout.bind(this);
  }

  componentDidMount() {
    const { authorized } = this.state;

    if (authorized === 0) {
      Service.User.Authenticated().then(response => {
        if (response !== false) {
          const items = [];

          Object.keys(response.menuItemsWithGroup).forEach(group => {
            response.menuItemsWithGroup[group].forEach(item => items.push(item));
          });

          const user = Partner.utilities.user();
          user.publisherAccount = response.publisherAccount;
          localStorage.setItem('user', JSON.stringify(user));

          this.setState({
            authorized: 1,
            menu: response.menuItemsWithGroup,
            menuItems: items,
          });
        } else {
          this.setState({
            authorized: 2,
          });
        }
      });
    }
  }

  onLogin(data, callBack) {
    Service.User.Login(data).then(response => {
      if (response && response.token) {
        localStorage.removeItem('user');
        localStorage.removeItem('reportDate');
        localStorage.removeItem('publisher');
        localStorage.setItem('user', JSON.stringify(response));

        this.setState({ authorized: 1 });
        window.location.reload();

        callBack(true);
      } else {
        this.setState({ authorized: 2 });
        callBack(false);
      }
    });
  }

  onLogout(e) {
    e.preventDefault();

    Service.User.Logout();
    this.setState({
      authorized: 2,
      menu: null,
      menuItems: [],
    });
  }

  render() {
    const { authorized, menu, menuItems } = this.state;
    const { children } = this.props;
    return (
      <AuthContext.Provider
        value={{
          authorized,
          menu,
          menuItems,
          onLogin: this.onLogin,
          onLogout: this.onLogout,
        }}
      >
        {children}
      </AuthContext.Provider>
    );
  }
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const AuthRoute = ({ component: Component, ...rest }) => (
  <AuthConsumer>
    {({ authorized, onLogout, menu }) => {
      let content = '';

      if (authorized === 1) {
        content = (
          <Route
            render={props => (
              <Layout topbar footer sidebar onLogout={onLogout} menu={menu}>
                <Component {...props} />
              </Layout>
            )}
            {...rest}
          />
        );
      } else if (authorized === 2) {
        content = <Redirect to="/login" />;
      }
      return content;
    }}
  </AuthConsumer>
);

AuthRoute.propTypes = {
  component: PropTypes.func.isRequired,
};

export { AuthConsumer, AuthProvider, AuthRoute, AuthContext };
