import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { T } from 'react-translator-component';


export const ErrorPage = props => {
  const { match: { params } } = props;

  const { error = 404 } = params;

  const ErrorContent = {
    401: {
      title: 'Unauthorized Page',
      description: 'You do not have permission to view this page.',
    },
    404: {
      title: 'Sorry, page not found',
      description: 'The page you are looking for cannot be found. Please check the url.',
    },
    500: {
      title: 'Internal Server Error',
      description: 'There was an error. Please try again later.',
    },
  };

  const onBack = () => {
    window.history.back();
  };

  return (
    <div className="ex-pages">
      <div className="content-center">
        <div className="content-desc-center">
          <div className="container">
            <div className="card mo-mt-2">
              <div className="card-body">
                <div className="row align-items-center">
                  <div className="col-lg-4 offset-lg-1">
                    <div className="ex-page-content">
                      <h1 className="text-dark">{`${error}!`}</h1>
                      <h4 className="mb-4">{T(ErrorContent[error].title)}</h4>
                      <p className="mb-5">{T(ErrorContent[error].description)}</p>
                      <button type="button" className="btn btn-secondary mb-5 waves-effect waves-light mr-3" onClick={() => onBack()}>
                        <i className="fas fa-arrow-left mr-2" />
                        {T('Back')}
                      </button>
                      <Link className="btn btn-primary mb-5 waves-effect waves-light" to="/">
                        <i className="mdi mdi-home mr-1" />
                        {T('Back to Home')}
                      </Link>
                    </div>

                  </div>
                  <div className="col-lg-5 offset-lg-1">
                    <img src="/assets/images/error.svg" alt="veltrix" className="img-fluid mx-auto d-block" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ErrorPage.propTypes = {
  match: PropTypes.object.isRequired,
};
