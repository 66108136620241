/* eslint-disable quote-props */
module.exports = {
  'This field is required.': '此栏必须填写',
  'Ticket': '券',
  'Symbol': '符号',
  'Volume': '量',
  'Open Time': '开仓时间',
  'Close Time': '平仓时间',
  'Profit': '盈利',
  'Boss Id': 'Boss Id',
  'Company': '公司',
  'Update Date': '更新时间',
  'Fullname': '全名',
  'Agent': '经纪商',
  'Lead Status': '领导状态',
  'Call Status': '通话状态',
  'Source': '源',
  'Ib Id': 'Ib Id',
  'Ip Country': 'Ip 所在国家',
  'Country': '国家',
  'Login': '登录',
  'Group': '组',
  'Cmd': '指令',
  'Publisher': '推广',
  'Spread': '点差',
  'Calculated Spread': '计算点差',
  'Calculated Volume': '计算量',
  'Calculated Sum Volume': '计算总量',
  'Amount': '数量',
  'Calculated Amount': '计算数量',
  'Calculated Usd Amount': '计算美元数量',
  'Type': '类型',
  'Partner Panel': '代理平台',
  'Partner management panel.': '代理管理平台',
  'Email': '邮箱',
  'Enter email': '输入邮箱',
  'Password': '密码',
  'Enter password': '输入密码',
  'Log In': '登录',
  'Forgot your password?': '忘记密码？',
  'TRA Information Technologies': 'TRA 信息技术',
  'Forgot Password': '忘记密码',
  'Send Reset Link': '发送重置密码链接',
  'Error !': '错误！',
  'Enter your Email and instructions will be sent to you!': '输入邮箱，操作步骤会发送给您',
  'Reset Password': '重置密码',
  'New Password': '新密码',
  'Confirm Password': '确认密码',
  'Reset': '重置',
  'Please enter your email!': '请输入您的邮箱',
  'Unauthorized!': '没有权限',
  'Invalid Login Credentials.': '无效的登录凭证',
  'Logout': '登出',
  'Home': '主页面',
  'Reports': '报告',
  'Customers': '客户',
  'Leads': 'Leads',
  'Links': '链接',
  'Welcome': '欢迎',
  'Date Range': '日期范围',
  'Filter Overview': '筛选器概述',
  'Total': '总',
  'My Customers': '我的客户',
  'Sub IB': '子代理',
  'Deposit': '入金',
  'Withdrawal': '出金',
  'Active Clients': '活跃客户',
  'Active IB': '活跃IB',
  'Number of Leads': 'Leads总数',
  'First Time Deposit': '首次如今吗时间',
  'Filter': '筛选',
  'Rebate': '返佣',
  'Lot Volume': '手数',
  'Net Deposit': '净存款',
  'Active': '活跃',
  'Other Information': '其他信息',
  'Filter Report': '筛选报告',
  'Total Rebate': '总返佣',
  'Rebate Report': '返佣报告',
  'Please select company and date range to check your related rebate report.': '请选择公司和时间范围来查看您的相关返佣报告',
  'Trades': '交易',
  'Name': '姓名',
  'City': '城市',
  'E-Mail': '邮箱',
  'Lead Source': 'lead 源',
  'Phone': '电话',
  'Registration Date': '注册时间',
  'Please select your company and publisher(IB ID number) to check customer list.': '请选择您的公司和代理推广号（代理ID号）来查看您的客户名单',
  'Please select your company and publisher to check lead list.': '请选择您的公司和代理推广号来查看你的Lead名单',
  'Required. Select the company where the account will be opened.': '必填，选择账户开立的公司',
  'Required. Select the publisher where the account will be attached.': '必填，选择账户开立的代理号',
  'Account Group': '账户组别',
  'Meta Group': 'Meta 组别',
  'Required. Select the user group where the account will be opened.': '必填，选择账户开立的用户组',
  'Create URL': '创建URL',
  'Dear': '尊敬的',
  "Please click on 'Create URL' to see the tracking link. <br />Details below might increase the efficiency of our joint campaign.": '请点击“创建 URL”来查看跟踪链接，以下详细信息可能会提高我们参加活动的的效率。',
  'My Ibs': '我的代理',
  'Ibs': '代理',
  'My Accounts': '我的账户',
  'Trade': '交易',
  'An error was occured.': '出现错误',
  'All areas are required!': '所有栏都必须填写',
  'Invalid email address for your token': '令牌的电子邮件地址不正确',
  'Invalid token': '无效的令牌',
  'Your password has been successfully updated.': '您的密码已经成功更新',
  'Please enter your email!': '请输入您的邮箱！',
  'Passwords did not be matched': '密码不匹配',
  'Login or password can not be empty!': '账户和密码不能为空',
  'Login or password is incorrect': '账户或密码错误',
  'All': '所有',
};
