import React from 'react';
import HttpsRedirect from 'react-https-redirect';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { createBrowserHistory as History } from 'history';
import { Translator } from 'react-translator-component';
import './App.css';
import './config/Utilities';
import 'react-data-table-component-extensions/dist/index.css';
import { Dashboard, ErrorPage, Login, Customers, Leads, Marketing, Reports, RecoverPassword, ResetPassword } from './views';
import {
  MenuItem,
  Symbol,
  User,
  UserGroupType,
  UserGroup,
  SymbolConvertionType,
  SecurityGroup,
  SymbolSecurity,
  ProductGroup,
  MenuItemRole,
  Role,
  Company,
  UserBossAccount,
  UserPublisherAccount,
  Rebate,
  ManualRebate,
  DailyRebate,
  UserDetail,
  RebateReport,
} from './views/Admin';
import { AuthProvider, AuthRoute, AuthConsumer } from './contexts/Authentication';

const views = {
  Dashboard,
  Customers,
  Leads,
  Marketing,
  Reports,
  MenuItem,
  Symbol,
  User,
  UserGroupType,
  UserGroup,
  SymbolConvertionType,
  SecurityGroup,
  SymbolSecurity,
  ProductGroup,
  MenuItemRole,
  Role,
  Company,
  UserBossAccount,
  UserPublisherAccount,
  Rebate,
  ManualRebate,
  DailyRebate,
  UserDetail,
  RebateReport,
};

function App() {
  return (
    <HttpsRedirect>
      <Router history={History()}>
        <AuthProvider>
          <AuthConsumer>
            {({ menuItems, authorized }) => (
              <Translator>
                <Switch>
                  <Route exact path="/login" component={Login} />
                  <Route exact path="/recoverpassword" component={RecoverPassword} />
                  <Route exact path="/resetpassword/:token" component={ResetPassword} />

                  {/* AUTH ROUTE */}
                  {menuItems.map(item => (
                    <AuthRoute key={item.path} exact path={item.path.split(',')} component={views[item.component]} />
                  ))}

                  {/* Error Pages */}
                  <Route exact path="/error/:error" component={ErrorPage} />
                  {menuItems.length !== 0 && <Route component={ErrorPage} />}
                  {(authorized === 2 && document.location.pathname !== '/login') && <Redirect to="/login" />}
                </Switch>
              </Translator>
            )}
          </AuthConsumer>

        </AuthProvider>
      </Router>
    </HttpsRedirect>
  );
}

export default App;
