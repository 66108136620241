import DataTableExtensions from 'react-data-table-component-extensions';
import DataTable from 'react-data-table-component';
import { T } from 'react-translator-component';
import ValidForm from 'react-valid-form-component';
import Select from 'react-select';
import React, { Component } from 'react';
import { BreadCrumb, Card, Modal, ErrorMessage, Validation } from '../../components';
import { DataTableColumns } from '../../config/DataTableColumns';
import { Service } from '../../services';
import { Partner } from '../../config/AppConfig';

export class MenuItem extends Component {
  constructor(props) {
    super(props);

    const pageConfig = 'menuItem';

    this.state = {
      dataTable: {
        columns: DataTableColumns.admin[pageConfig],
        rows: [],
      },
      modal: false,
      url: Partner.service.admin[pageConfig],
      validation: {
        error: false,
        message: '',
        checkValid: false,
      },
      data: {
        menuGroupSelected: 0,
      },
      list: {
        menuGroup: [],
      },
    };

    this.onEdit = this.onEdit.bind(this);
  }

  componentDidMount() {
    this.getTable();
  }

  onChangeSelect(val, key) {
    const { data } = this.state;
    data[key] = val;

    this.setState({
      data,
    });
  }

  onNew() {
    this.setState({
      modal: true,
      data: {},
      validation: {
        error: false,
        message: '',
        checkValid: false,
      },
    });
  }

  onEdit(id) {
    const { url, list } = this.state;
    let { data } = this.state;
    Service.Admin.Detail({ id }, url).then(response => {
      if (response && response.error === false) {
        data = response.data;
        const [menuGroup] = list.menuGroup.filter(f => f.value === data.menuGroupId);
        data.menuGroupSelected = menuGroup;
        this.setState({
          data,
          validation: {
            error: false,
            message: '',
            checkValid: false,
          },
          modal: true,
        });
      }
    });
  }

  onSave(data, valid) {
    const { url } = this.state;

    this.checkValid();

    if (valid) {
      const { data: thisData } = this.state;
      if (thisData.menuGroupSelected) {
        const postData = data;
        postData.menuGroupId = thisData.menuGroupSelected.value;

        Service.Admin.Save(data, url).then(response => {
          if (response) {
            if (response.error === false) {
              this.setState({
                modal: false,
                data: {},
              });
              this.getTable();
            } else {
              this.setState({
                validation: {
                  error: response.error,
                  message: response.message,
                  checkValid: false,
                },
              });
            }
          }
        });
      }
    }
  }

  getTable() {
    const { dataTable, url, list } = this.state;
    Service.Admin.List(url).then(response => {
      if (response && response.error === false) {
        dataTable.rows = response.data.data;
        list.menuGroup = response.data.common.menuGroup;
        this.setState({
          dataTable,
          list,
        });
      }
    });
  }

  checkValid() {
    this.setState(prevState => ({
      validation: {
        ...prevState.validation,
        checkValid: true,
      },
    }));
  }

  render() {
    const { dataTable, data, validation, modal, list } = this.state;
    return (
      <>
        <BreadCrumb Title="Menu Item" Description="Lorem ipsum dolor sit amet" />
        <Card>
          <Modal Title={T('Edit Menu Item')} Show={modal} onClose={() => this.setState({ modal: false, data: {} })}>
            <div className="row mt-1 mb-1">
              <p className="text-muted mb-2 ml-1 col-md-12 font-12">{T('Menu Item')}</p>
              <div className="col-md-12">
                <ValidForm className="form-horizontal m-t-10" nosubmit novalid onSubmit={(form, formData, valid) => this.onSave(formData, valid)} data={data}>
                  <div className="form-group">
                    <div className="row">
                      <ErrorMessage Validation={validation} />
                    </div>
                  </div>
                  <div className="form-group">
                    <input type="hidden" className="form-control" id="id" name="id" />
                  </div>
                  <div className="form-group row ml-1 mr-1">
                    <label htmlFor="login" className="col-sm-4 col-form-label">{T('Title')}</label>
                    <div className="col-sm-8">
                      <input type="text" className="form-control" id="title" name="title" placeholder={T('Enter title')} required />
                    </div>
                  </div>
                  <div className="form-group row ml-1 mr-1">
                    <label htmlFor="login" className="col-sm-4 col-form-label">{T('Menu Group')}</label>
                    <div className="col-sm-8">
                      <Select
                        value={data.menuGroupSelected}
                        options={list.menuGroup}
                        onChange={option => this.onChangeSelect(option, 'menuGroupSelected')}
                        isMulti={false}
                      />
                      <Validation Value={data.menuGroupSelected} Check={validation.checkValid} />
                    </div>
                  </div>
                  <div className="form-group row ml-1 mr-1">
                    <label htmlFor="login" className="col-sm-4 col-form-label">{T('Path')}</label>
                    <div className="col-sm-8">
                      <input type="text" className="form-control" id="path" name="path" placeholder={T('Enter path')} required />
                    </div>
                  </div>
                  <div className="form-group row ml-1 mr-1">
                    <label htmlFor="login" className="col-sm-4 col-form-label">{T('Class')}</label>
                    <div className="col-sm-8">
                      <input type="text" className="form-control" id="class" name="class" placeholder={T('Enter class')} required />
                    </div>
                  </div>
                  <div className="form-group row ml-1 mr-1">
                    <label htmlFor="login" className="col-sm-4 col-form-label">{T('Component')}</label>
                    <div className="col-sm-8">
                      <input type="text" className="form-control" id="component" name="component" placeholder={T('Enter component')} required />
                    </div>
                  </div>
                  <div className="form-group row m-t-20 mr-1">
                    <div className="col-sm-12 text-right">
                      <button type="submit" className="btn btn-success w-md waves-effect waves-light">{T('Save')}</button>
                    </div>
                  </div>
                </ValidForm>
              </div>
            </div>
          </Modal>
          <div className="row">
            <div className="col-md-12">
              <button type="button" className="btn btn-info position-absolute add-new-button tabless" onClick={() => this.onNew()}>
                <i className="fas fa-plus  mr-2" />
                {T('Add New')}
              </button>
              <DataTableExtensions columns={dataTable.columns(this.onEdit)} data={dataTable.rows} export={false} print={false}>
                <DataTable
                  className="tbl-admin-menuitem"
                  noHeader
                  defaultSortField="id"
                  defaultSortAsc={false}
                  pagination
                  highlightOnHover
                />
              </DataTableExtensions>
            </div>
          </div>
        </Card>
      </>
    );
  }
}
