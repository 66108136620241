import { Partner } from '../config/AppConfig';
import { Fetch } from '../components';


export const AdminService = {
  /**
  * [FILTER]
  */
  Filter(url) {
    const requestOptions = {
      method: 'GET',
      headers: Partner.utilities.authorizedHeader(),
    };

    return Fetch(url, requestOptions);
  },
  /**
  * [TRADE]
  */
  Trade(url, data) {
    const requestOptions = {
      method: 'POST',
      headers: Partner.utilities.authorizedHeader(),
      body: JSON.stringify(data),
    };

    return Fetch(url, requestOptions);
  },
  /**
  * [REPORT]
  */
  Report(url, data) {
    const requestOptions = {
      method: 'POST',
      headers: Partner.utilities.authorizedHeader(),
      body: JSON.stringify(data),
    };

    return Fetch(url, requestOptions);
  },
  /**
  * [TRADE]
  */
  ManualRebate(url, data) {
    const requestOptions = {
      method: 'POST',
      headers: Partner.utilities.authorizedHeader(),
      body: JSON.stringify(data),
    };

    return Fetch(url, requestOptions);
  },
  /**
  * [LIST]
  */
  List(url) {
    const requestOptions = {
      method: 'GET',
      headers: Partner.utilities.authorizedHeader(),
    };

    return Fetch(url.list, requestOptions);
  },
  /**
  * [SAVE]
  */
  Save(data, url) {
    const requestOptions = {
      method: 'POST',
      headers: Partner.utilities.authorizedHeader(),
      body: JSON.stringify(data),
    };

    return Fetch(url.save, requestOptions);
  },
  /**
  * [DETAIL]
  */
  Detail(data, url) {
    const requestOptions = {
      method: 'POST',
      headers: Partner.utilities.authorizedHeader(),
      body: JSON.stringify(data),
    };

    return Fetch(url.detail, requestOptions);
  },
};
