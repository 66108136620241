import React, { Component } from 'react';
import { BreadCrumb } from '../../components';
import { UserPublisherAccount } from './UserPublisherAccount';
import { UserBossAccount } from './UserBossAccount';
import { Rebate } from './Rebate';
import { Partner } from '../../config/AppConfig';


export class UserDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userId: Partner.utilities.publisher(),
    };
  }

  render() {
    const { userId } = this.state;
    return (
      <>
        <BreadCrumb Title="User Detail" Description="Lorem ipsum dolor sit amet" />
        <div className="row">
          <div className="col-md-12">
            <Rebate Detail UserId={userId} />
          </div>
          <div className="col-md-12">
            <UserPublisherAccount Detail UserId={userId} />
          </div>
          <div className="col-md-12">
            <UserBossAccount Detail UserId={userId} />
          </div>
        </div>
      </>
    );
  }
}
