import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { T } from 'react-translator-component';
import { Service } from '../../services';
import { ErrorMessage } from '../../components/ErrorMessage';

export class RecoverPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        email: '',
      },
      validation: {
        error: false,
        message: 'Enter your Email and instructions will be sent to you!',
      },
      disabled: false,
    };
  }

  componentDidMount() {

  }

  onChange(key, value) {
    const { formData } = this.state;
    formData[key] = value;
    this.setState({
      formData,
    });
  }

  onReset() {
    const { formData } = this.state;
    if (formData.email === null || formData.email === '') {
      this.setState({
        validation: {
          error: true,
          message: 'Please enter your email!',
        },
      });
      return;
    }

    Service.User.RecoverPassword({ email: formData.email }).then(response => {
      if (response) {
        this.setState({
          validation: {
            error: response.error,
            message: T(response.message),
          },
          disabled: !response.error,
        });
      }
    });
  }

  render() {
    const { validation, formData, disabled } = this.state;
    return (
      <>
        <div className="home-btn d-none d-sm-block">
          <Link to="/" className="text-dark"><i className="fas fa-home h2" /></Link>
        </div>

        <div className="wrapper-page">
          <div className="card overflow-hidden account-card mx-3">
            <div className="bg-dark p-4 text-white text-center position-relative">
              <h4 className="font-20 mb-4">{T('Forgot Password')}</h4>
              <Link to="/login" className="logo logo-admin"><img src="assets/images/partner-icon.svg" height="24" alt="logo" /></Link>
            </div>

            <div className="account-card-content">
              <ErrorMessage Validation={validation} />
              {validation.error
                ? ''
                : (
                  <div className="alert alert-success m-t-30" role="alert">
                    {validation.message}
                  </div>
                )}

              <div className="form-group">
                <label htmlFor="useremail">{T('Email')}</label>
                <input type="email" className="form-control" autoComplete="off" id="useremail" disabled={disabled} onChange={e => this.onChange('email', e.target.value)} value={formData.email} placeholder={T('Enter email')} />
              </div>

              <div className="form-group row m-t-20 mb-0">
                <div className="col-12 text-right">
                  <button className="btn btn-dark w-md waves-effect waves-light" type="button" disabled={disabled} onClick={e => this.onReset(e)}>{T('Send Reset Link')}</button>
                </div>
              </div>
              <div className="form-group m-t-10 mb-0 row">
                <div className="col-12 m-t-20">
                  <Link to="/login">
                    <i className="fas fa-angle-double-left mr-1" />
                    <span>{T('Login')}</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="m-t-40 text-center">
            <p>{`© ${new Date().getFullYear()} ${T('TRA Information Technologies')}`}</p>
          </div>
        </div>
      </>
    );
  }
}
