/* eslint-disable no-undef */
import React, { useContext, useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { T, LanguageList } from 'react-translator-component';
import ValidForm from 'react-valid-form-component';
import { AuthContext } from '../../contexts/Authentication';

export const Login = () => {
  const [Error, setError] = useState(false);
  const { authorized, onLogin } = useContext(AuthContext);
  const [captchaError, setCaptchError] = useState(null);

  const callBack = isAuth => {
    setError(!isAuth);
  };

  const onSubmit = (form, data) => {
    const response = grecaptcha.getResponse();
    if (!response) {
      setCaptchError('Please verify that you are not a robot.');
      return;
    }
    setCaptchError(null);
    data.captchaToken = response;
    onLogin(data, callBack);
  };

  useEffect(() => {
    window.onloadCallback = function onloadCallback() {
      grecaptcha.render(document.getElementById('g-recaptcha'), {
        sitekey: '6LfVaf4qAAAAAM7hR4AP3Cxixe-yHKW_WPqXkAJZ',
      });
    };
    const script = document.createElement('script');
    script.src = 'https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=explicit';
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  if (authorized === 1) {
    return <Redirect to="/home" />;
  }

  return (
    <div className="wrapper-page">
      <div className="card account-card mx-3">
        <div className="bg-warning p-4 text-white text-center position-relative">
          <h4 className="font-20 m-b-5">{T('Partner Panel')}</h4>
          <p className="text-white-50 mb-4">{T('Partner management panel.')}</p>
          <Link to="/login" className="logo logo-admin"><img src="assets/images/partner-icon.svg" height="24" alt="logo" /></Link>
        </div>
        <div className="account-card-content">
          {(Error) ?
            (
              <div className="alert alert-danger alert-dismissible fade show mt-3" role="alert">
                <strong className="mr-2">{T('Unauthorized!')}</strong>
                {T('Invalid Login Credentials.')}
              </div>
            ) : ''}
          <ValidForm className="form-horizontal m-t-30" nosubmit onSubmit={onSubmit}>
            <div className="form-group">
              <label htmlFor="email">{T('Email')}</label>
              <input type="email" className="form-control" id="email" name="email" placeholder={T('Enter email')} required />
            </div>
            <div className="form-group">
              <label htmlFor="password">{T('Password')}</label>
              <input type="password" className="form-control" id="password" name="password" placeholder={T('Enter password')} required />
            </div>
            <div className="form-group">
              <div className="g-recaptcha" id="g-recaptcha" data-sitekey="6LcM9O0UAAAAAOhX3qZ9wKQ0h8DZ6v8z9Z9Ff4Q1" />
              {
                captchaError && <div id="captcha-error" style={{ color: 'red', marginTop: '4px' }}>{captchaError}</div>
              }
            </div>
            <div className="form-group row m-t-20">
              <div className="col-sm-6">
                <LanguageList Theme="Dropdown" />
              </div>
              <div className="col-sm-6 text-right">
                <button type="submit" className="btn btn-warning w-md waves-effect waves-light">{T('Log In')}</button>
              </div>
            </div>
            <div className="form-group m-t-10 mb-0 row">
              <div className="col-12 m-t-20">
                <Link to="/recoverpassword">
                  <i className="mdi mdi-lock mr-2" />
                  <span>{T('Forgot your password?')}</span>
                </Link>
              </div>
            </div>
          </ValidForm>
        </div>
      </div>
      <div className="m-t-40 text-center">
        <p>{`© ${new Date().getFullYear()} ${T('TRA Information Technologies')}`}</p>
      </div>
    </div>
  );
};
