import DataTableExtensions from 'react-data-table-component-extensions';
import DataTable from 'react-data-table-component';
import React, { Component } from 'react';
import Select from 'react-select';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { isArray } from 'util';
import { T } from 'react-translator-component';
import { BreadCrumb, Card } from '../../components';
import { DataTableColumns } from '../../config/DataTableColumns';
import { Service } from '../../services';
import { Partner } from '../../config/AppConfig';

export class DailyRebate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataTable: {
        columns: DataTableColumns.admin.rebateTrade,
        rows: [],
      },
      filter: {
        companyList: [],
        companySelected: { value: -1, label: 'All' },
        publisherList: [],
        publisherSelected: [],
        rebateTypeList: [
          { value: null, label: T('All') },
          { value: 'Commission', label: T('Commission') },
          { value: 'CommissionSymbolSecurity', label: T('Commission Symbol Security') },
          { value: 'LotVolume', label: T('Lot Volume') },
          { value: 'Symbol', label: T('Symbol') },
          { value: 'SymbolSecurity', label: T('Symbol Security') },
          { value: 'IBrokerCommission', label: T('IB Commission') },
          { value: 'IBrokerCommissionSymbolSecurity', label: T('IB Commission Symbol Security') },
          { value: 'IBrokerLotVolume', label: T('IB Lot Volume') },
          { value: 'IBrokerSymbol', label: T('IB Symbol') },
          { value: 'IBrokerSymbolSecurity', label: T('IB Symbol Security') },
        ],
        rebateTypeSelected: { value: null, label: T('All') },
        beginDate: new Date(),
        endDate: new Date(),
      },
    };

    this.list = {
      publisher: new Map(),
    };

    this.onDateChange = this.onDateChange.bind(this);
    this.onFilter = this.onFilter.bind(this);
  }

  componentDidMount() {
    this.getTable();
  }


  onFilterChange(option, key) {
    const { filter } = this.state;

    filter[key] = option;

    if (key === 'companySelected' && option.value === -1) {
      let publisherList = [];

      this.list.publisher.forEach(value => {
        publisherList = publisherList.concat(value);
      });

      filter.publisherList = publisherList;
    } else if (key === 'companySelected') {
      filter.publisherList = this.list.publisher.get(option.value);
    }

    this.setState({
      filter,
    });
  }

  onDateChange(date) {
    const { filter } = this.state;

    if (date !== null && isArray(date)) {
      const [one, two] = date;
      filter.beginDate = one;
      filter.endDate = two;
      this.setState({
        filter,
      });
    }
  }

  onFilter() {
    const { filter, dataTable } = this.state;

    Service.Admin.Trade(Partner.service.admin.rebate.trade, {
      companyId: filter.companySelected.value,
      type: filter.rebateTypeSelected.value,
      publisher: filter.publisherSelected ? filter.publisherSelected.map(item => item.value) : [],
      beginDate: filter.beginDate,
      endDate: filter.endDate,
    }).then(response => {
      if (response) {
        dataTable.rows = response;
        this.setState({ dataTable });
      }
    });
  }

  getTable() {
    const { dataTable, filter } = this.state;
    Service.Admin.Filter(Partner.service.admin.rebate.filter).then(response => {
      if (response) {
        filter.companyList = [{ value: -1, label: 'All' }].concat(response.company);
        filter.publisherList = response.publisher.map(item => {
          this.list.publisher.set(item.companyId, item.list);
          return item.list;
        }).flat(1);

        this.setState({
          dataTable,
          filter,
        });
      }
    });
  }

  render() {
    const { dataTable, filter } = this.state;
    return (
      <>
        <BreadCrumb Title="Daily Rebate" Description="Lorem ipsum dolor sit amet" />
        <Card>
          <div className="row mb-4">
            <div className="col-md-12 m-2 mb-4">
              <div className="row">
                <div className="col-md-12 pp-filter-wrapper">
                  <div className="form-group mb-0">
                    <label>{T('Company')}</label>
                    <Select
                      value={filter.companySelected}
                      options={filter.companyList}
                      onChange={option => this.onFilterChange(option, 'companySelected')}
                      isMulti={false}
                    />
                  </div>
                  <div className="form-group mb-0">
                    <label>{T('Publisher')}</label>
                    <Select
                      value={filter.publisherSelected}
                      options={filter.publisherList}
                      onChange={option => this.onFilterChange(option, 'publisherSelected')}
                      isMulti
                    />
                  </div>
                  <div className="form-group mb-0">
                    <label>{T('Rebate Type')}</label>
                    <Select
                      value={filter.rebateTypeSelected}
                      options={filter.rebateTypeList}
                      onChange={option => this.onFilterChange(option, 'rebateTypeSelected')}
                      isMulti={false}
                    />
                  </div>
                  <div className="form-group mb-0">
                    <label>{T('Date Range')}</label>
                    <div>
                      <DateRangePicker
                        className="date-range-picker form-control"
                        onChange={this.onDateChange}
                        value={[filter.beginDate, filter.endDate]}
                        format="dd.MM.yyyy"
                      />
                    </div>
                  </div>
                  <div className="form-group mb-0">
                    <label>&nbsp;</label>
                    <button type="button" className="btn btn-dark bg-dark btn-block" onClick={() => this.onFilter()}>Filter</button>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <DataTableExtensions columns={dataTable.columns} data={dataTable.rows} export print exportHeaders>
                <DataTable
                  className="tbl-admin-symbol"
                  noHeader
                  defaultSortField="id"
                  defaultSortAsc={false}
                  pagination
                  highlightOnHover
                />
              </DataTableExtensions>
            </div>
          </div>
        </Card>
      </>
    );
  }
}
