import DataTableExtensions from 'react-data-table-component-extensions';
import DataTable from 'react-data-table-component';
import React, { Component } from 'react';
import Select from 'react-select';
import ValidForm from 'react-valid-form-component';
import { T } from 'react-translator-component';
import { BreadCrumb, Card, Modal, ErrorMessage, Validation } from '../../components';
import { DataTableColumns } from '../../config/DataTableColumns';
import { Service } from '../../services';
import { Partner } from '../../config/AppConfig';

export class Symbol extends Component {
  constructor(props) {
    super(props);

    const pageConfig = 'symbol';

    this.state = {
      dataTable: {
        columns: DataTableColumns.admin[pageConfig],
        rows: [],
      },
      modal: false,
      url: Partner.service.admin[pageConfig],
      validation: {
        error: false,
        message: '',
        checkValid: false,
      },
      data: {},
      filter: {
        companySelected: { label: 'All', value: '-1' },
        companyList: [{ label: 'All', value: '-1' }],
      },
      list: {
        symbolConvertionType: new Map(),
        symbolSecurity: new Map(),
        company: [],
      },
    };

    this.onEdit = this.onEdit.bind(this);
    this.tableRows = null;
  }

  componentDidMount() {
    this.getTable();
  }

  onChangeSelect(val, key) {
    const { data } = this.state;
    data[key] = val;
    if (key === 'companySelected') {
      data.companyId = val.value;
    }
    this.setState({
      data,
    });
  }

  onEdit(id) {
    const { url, list } = this.state;
    let { data } = this.state;
    Service.Admin.Detail({ id }, url).then(response => {
      if (response && response.error === false) {
        data = response.data;


        const [symbolSecurityId] = list.symbolSecurity.get(data.companyId).filter(f => f.value === data.symbolSecurityId);
        data.symbolSecurityId = symbolSecurityId;

        const [symbolConvertionTypeId] = list.symbolConvertionType.get(data.companyId).filter(f => f.value === data.symbolConvertionTypeId);
        data.symbolConvertionTypeId = symbolConvertionTypeId;
        const [companyId] = list.company.filter(f => f.value === data.companyId);
        data.companySelected = companyId;
        this.setState({
          data,
          validation: {
            error: false,
            message: '',
            checkValid: false,
          },
          modal: true,
        });
      }
    });
  }

  onSave(data, valid) {
    const { url } = this.state;
    const { data: thisData } = this.state;

    this.checkValid();

    if (valid) {
      if (thisData.companySelected && thisData.symbolSecurityId && thisData.symbolConvertionTypeId) {
        const postData = data;
        postData.companyId = thisData.companySelected.value;
        postData.symbolSecurityId = thisData.symbolSecurityId.value;
        postData.symbolConvertionTypeId = thisData.symbolConvertionTypeId.value;

        Service.Admin.Save(postData, url).then(response => {
          if (response) {
            if (response.error === false) {
              this.setState({
                modal: false,
                data: {},
              });
              this.getTable();
            } else {
              this.setState({
                validation: {
                  error: response.error,
                  message: response.message,
                  checkValid: false,
                },
              });
            }
          }
        });
      }
    }
  }

  onNew() {
    this.setState({
      modal: true,
      data: {},
      validation: {
        error: false,
        message: '',
        checkValid: false,
      },
    });
  }

  getTable() {
    const { dataTable, url, filter, list } = this.state;
    Service.Admin.List(url).then(response => {
      if (response && response.error === false) {
        dataTable.rows = response.data.data;

        response.data.common.symbolConvertionType.forEach(item => {
          list.symbolConvertionType.set(item.companyId, item.list);
        });
        response.data.common.symbolSecurity.forEach(item => {
          list.symbolSecurity.set(item.companyId, item.list);
        });

        list.company = response.data.common.company;

        filter.companyList = filter.companyList.concat(response.data.common.company);

        this.setState({
          dataTable,
          filter,
          list,
        });

        this.tableRows = response.data.data;
      }
    });
  }

  checkValid() {
    this.setState(prevState => ({
      validation: {
        ...prevState.validation,
        checkValid: true,
      },
    }));
  }

  render() {
    const { dataTable, data, validation, modal, filter, list } = this.state;
    return (
      <>
        <BreadCrumb Title="Symbol" Description="Lorem ipsum dolor sit amet" />
        <Card>
          <Modal isScrollable Title={T('Edit Symbol')} Show={modal} onClose={() => this.setState({ modal: false, data: {} })}>
            <div className="row mt-1 mb-1">
              <p className="text-muted mb-2 col-md-12 font-12">{T('Symbol')}</p>
              <div className="col-md-12">
                <ValidForm className="form-horizontal m-t-10" nosubmit novalid onSubmit={(form, formData, valid) => this.onSave(formData, valid)} data={data}>
                  <div className="form-group">
                    <div className="row">
                      <ErrorMessage Validation={validation} />
                    </div>
                  </div>
                  <div className="form-group">
                    <input type="hidden" className="form-control" id="id" name="id" />
                  </div>
                  <div className="form-group row ml-1 mr-1">
                    <label htmlFor="login" className="col-sm-4 col-form-label">{T('Company')}</label>
                    <div className="col-sm-8">
                      <Select
                        value={data.companySelected}
                        options={list.company}
                        onChange={option => this.onChangeSelect(option, 'companySelected')}
                        isMulti={false}
                      />
                      <Validation Value={data.companySelected} Check={validation.checkValid} />
                    </div>
                  </div>
                  <div className="form-group row ml-1 mr-1">
                    <label htmlFor="login" className="col-sm-4 col-form-label">{T('Symbol Security')}</label>
                    <div className="col-sm-8">
                      <Select
                        value={data.symbolSecurityId}
                        options={list.symbolSecurity.get(data.companyId)}
                        onChange={option => this.onChangeSelect(option, 'symbolSecurityId')}
                        isMulti={false}
                      />
                      <Validation Value={data.symbolSecurityId} Check={validation.checkValid} />
                    </div>
                  </div>
                  <div className="form-group row ml-1 mr-1">
                    <label htmlFor="login" className="col-sm-4 col-form-label">{T('Symbol')}</label>
                    <div className="col-sm-8">
                      <input type="text" className="form-control" id="symbol1" name="symbol1" placeholder={T('Symbol')} required />
                    </div>
                  </div>
                  <div className="form-group row ml-1 mr-1">
                    <label htmlFor="login" className="col-sm-4 col-form-label">{T('Contract Size')}</label>
                    <div className="col-sm-8">
                      <input type="number" className="form-control" id="contractSize" name="contractSize" placeholder={T('Contract Size')} required />
                    </div>
                  </div>
                  <div className="form-group row ml-1 mr-1">
                    <label htmlFor="login" className="col-sm-4 col-form-label">{T('Currency')}</label>
                    <div className="col-sm-8">
                      <input type="text" className="form-control" id="currency" name="currency" placeholder={T('Currency')} required />
                    </div>
                  </div>
                  <div className="form-group row ml-1 mr-1">
                    <label htmlFor="login" className="col-sm-4 col-form-label">{T('Margin Currency')}</label>
                    <div className="col-sm-8">
                      <input type="text" className="form-control" id="marginCurrency" name="marginCurrency" placeholder={T('Margin Currency')} required />
                    </div>
                  </div>
                  <div className="form-group row ml-1 mr-1">
                    <label htmlFor="login" className="col-sm-4 col-form-label">{T('Base Currency')}</label>
                    <div className="col-sm-8">
                      <input type="text" className="form-control" id="baseCurrency" name="baseCurrency" placeholder={T('Base Currency')} required />
                    </div>
                  </div>
                  <div className="form-group row ml-1 mr-1">
                    <label htmlFor="login" className="col-sm-4 col-form-label">{T('Base Multiplier')}</label>
                    <div className="col-sm-8">
                      <input type="text" className="form-control" id="baseMultiplier" name="baseMultiplier" placeholder={T('Base Multiplier')} required />
                    </div>
                  </div>
                  <div className="form-group row ml-1 mr-1">
                    <label htmlFor="login" className="col-sm-4 col-form-label">{T('Symbol Convertion Type')}</label>
                    <div className="col-sm-8">
                      <Select
                        value={data.symbolConvertionTypeId}
                        options={list.symbolConvertionType.get(data.companyId)}
                        onChange={option => this.onChangeSelect(option, 'symbolConvertionTypeId')}
                        isMulti={false}
                      />
                      <Validation Value={data.symbolConvertionTypeId} Check={validation.checkValid} />
                    </div>
                  </div>
                  <div className="form-group row ml-1 mr-1">
                    <label htmlFor="login" className="col-sm-4 col-form-label">{T('Multiplier')}</label>
                    <div className="col-sm-8">
                      <input type="text" className="form-control" id="multiplier" name="multiplier" placeholder={T('Multiplier')} required />
                    </div>
                  </div>
                  <div className="form-group row ml-1 mr-1">
                    <label htmlFor="login" className="col-sm-4 col-form-label">{T('Divider')}</label>
                    <div className="col-sm-8">
                      <input type="text" className="form-control" id="divider" name="divider" placeholder={T('Divider')} required />
                    </div>
                  </div>
                  <div className="form-group row m-t-20 mr-1">
                    <div className="col-sm-12 text-right">
                      <button type="submit" className="btn btn-success w-md waves-effect waves-light">{T('Save')}</button>
                    </div>
                  </div>
                </ValidForm>
              </div>
            </div>
          </Modal>
          <div className="row">
            <div className="col-md-12 mb-5">
              <div className="col-md-2">
                <div className="form-group mb-0">
                  <label>{T('Company')}</label>
                  <Select
                    value={filter.companySelected}
                    options={filter.companyList}
                    onChange={option => {
                      if (option.value === '-1') {
                        dataTable.rows = this.tableRows;
                      } else {
                        dataTable.rows = this.tableRows.filter(f => f.companyId === option.value);
                      }

                      filter.companySelected = option;

                      this.setState({ filter, dataTable });
                    }}
                    isMulti={false}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <button type="button" className="btn btn-info position-absolute add-new-button tabless" onClick={() => this.onNew()}>
                <i className="fas fa-plus mr-2" />
                {T('Add New')}
              </button>
              <DataTableExtensions columns={dataTable.columns(this.onEdit)} data={dataTable.rows} export={false} print={false}>
                <DataTable
                  className="tbl-admin-symbol"
                  noHeader
                  defaultSortField="id"
                  defaultSortAsc={false}
                  pagination
                  highlightOnHover
                />
              </DataTableExtensions>
            </div>
          </div>
        </Card>
      </>
    );
  }
}
