import DataTableExtensions from 'react-data-table-component-extensions';
import DataTable from 'react-data-table-component';
import React, { Component } from 'react';
import Select from 'react-select';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { isArray } from 'util';
import { T } from 'react-translator-component';
import { BreadCrumb, Card, Validation, Confirm } from '../../components';
import { Service } from '../../services';
import { Partner } from '../../config/AppConfig';

export class ManualRebate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      validation: false,
      dataTable: {
        columns: [],
        rows: [],
      },
      result: {
        tradeCount: 0,
        totalRebateUSD: 0,
        elapsed: 0,
        existTradeCount: 0,
        existTotalRebateUSD: 0,
      },
      filter: {
        isTested: false,
        companyList: [],
        companySelected: undefined,
        publisherList: [],
        publisherSelected: [],
        rebateTypeList: [
          { value: '5', label: T('Commission') },
          { value: '6', label: T('Commission Symbol Security') },
          { value: '7', label: T('Lot Volume') },
          { value: '8', label: T('Symbol') },
          { value: '9', label: T('Symbol Security') },
          { value: '0', label: T('IB Commission') },
          { value: '1', label: T('IB Commission Symbol Security') },
          { value: '2', label: T('IB Lot Volume') },
          { value: '3', label: T('IB Symbol') },
          { value: '4', label: T('IB Symbol Security') },
        ],
        rebateTypeSelected: undefined,
        beginDate: new Date(),
        endDate: new Date(),
        test: true,
      },
    };

    this.list = {
      publisher: new Map(),
    };

    this.onDateChange = this.onDateChange.bind(this);
    this.onCalculate = this.onCalculate.bind(this);
  }

  componentDidMount() {
    this.getTable();
  }


  onFilterChange(option, key) {
    const { filter } = this.state;

    filter[key] = option;

    if (key === 'companySelected' && option.value === -1) {
      let publisherList = [];

      this.list.publisher.forEach(value => {
        publisherList = publisherList.concat(value);
      });

      filter.publisherList = publisherList;
    } else if (key === 'companySelected') {
      filter.publisherList = this.list.publisher.get(option.value);
    }

    this.resetResult();

    this.setState({
      filter,
    });
  }

  onDateChange(date) {
    const { filter } = this.state;

    if (date !== null && isArray(date)) {
      const [one, two] = date;
      filter.beginDate = one;
      filter.endDate = two;

      this.setState({
        filter,
      });
    }

    this.resetResult();
  }

  onCalculate() {
    const { filter, dataTable, result } = this.state;

    this.setState({
      validation: true,
    });

    if (filter.companySelected && filter.rebateTypeSelected) {
      let confirmDescription = 'Rebates will be calculate for all <b>publishers</b>. Do you want to continue?';

      if (filter.publisherSelected.length !== 0) {
        confirmDescription = 'Rebates will be calculate for <b>selected</b> publishers. Do you want to continue?';
      }

      Confirm({
        title: T('Are you sure?'),
        type: { warning: true },
        description: T(confirmDescription),
        onConfirm: () => {
          Service.Admin.ManualRebate(Partner.service.admin.rebate.manual, {
            Type: filter.rebateTypeSelected.value,
            CompanyId: filter.companySelected.value,
            BeginDate: filter.beginDate,
            EndDate: filter.endDate,
            Publisher: filter.publisherSelected.map(item => item.value),
            Test: filter.test,
            Manual: !filter.test,
          }).then(response => {
            if (response && response.trades.length > 0) {
              const columnFilter = ['id', 'companyId', 'convRate1', 'convRate2', 'productGroupId', 'securityGroupId', 'symbolSecurityId', 'userGroupTypeId', 'userGroupId', 'oldId'];
              dataTable.columns = Object.keys(response.trades[0]).filter(item => !columnFilter.includes(item)).map(item => ({
                name: item.toCapitalizeCase().replace(/([a-z])([A-Z])/g, '$1 $2'),
                selector: item,
                sortable: true,
              }));
              dataTable.rows = response.trades;

              result.elapsed = (response.elapsed / 1000);
              result.totalRebateUSD = response.totalRebateUSD;
              result.tradeCount = response.tradeCount;

              result.existTradeCount = response.existTradeCount;
              result.existTotalRebateUSD = response.existTotalRebateUSD;

              if (!filter.test) {
                filter.test = true;
                filter.isTested = false;
              } else {
                filter.isTested = true;
              }


              this.setState({
                dataTable,
                result,
                filter,
              });
            } else if (response) {
              filter.isTested = true;

              this.setState({
                filter,
              });
            }
          });
        },
        onCancel: () => {

        },
      });
    }
  }

  getTable() {
    const { dataTable, filter } = this.state;
    Service.Admin.Filter(Partner.service.admin.rebate.filter).then(response => {
      if (response) {
        filter.companyList = response.company;
        filter.publisherList = response.publisher.map(item => {
          this.list.publisher.set(item.companyId, item.list);
          return item.list;
        }).flat(1);

        this.setState({
          dataTable,
          filter,
        });
      }
    });
  }

  resetResult() {
    const { filter, result, dataTable } = this.state;

    filter.isTested = false;
    filter.test = true;

    result.existTotalRebateUSD = 0;
    result.existTradeCount = 0;
    result.totalRebateUSD = 0;
    result.tradeCount = 0;
    result.elapsed = 0;

    dataTable.rows = [];
    dataTable.columns = [];

    this.setState({
      filter,
      result,
      dataTable,
    });
  }

  render() {
    const { dataTable, filter, validation, result } = this.state;
    return (
      <>
        <BreadCrumb Title="Manual Rebate" Description="Lorem ipsum dolor sit amet" />
        <Card Title="Manual Rebate" Description="Lorem ipsum dolor sit amet">
          <div className="row mb-4 m-2">
            <div className="col-md-12 mb-4">
              {!filter.test && (
                <div className="alert alert-danger mb-4 font-15" role="alert">
                  <strong className="mr-1">Attention!</strong>
                  <span>{`Existing rebate calculations will be deleted! Total records to be deleted ${result.existTradeCount} Trades, ${result.existTotalRebateUSD.toFixed(4)} USD.`}</span>
                </div>
              )}
              <div className="row pp-manual-rebate">
                <div className="col-md-12 pp-filter-wrapper">
                  <div className="form-group mb-0">
                    <label>{T('Company')}</label>
                    <Select
                      value={filter.companySelected}
                      options={filter.companyList}
                      onChange={option => this.onFilterChange(option, 'companySelected')}
                      isMulti={false}
                    />
                    <Validation Value={filter.companySelected} Check={validation} />
                  </div>

                  <div className="form-group mb-0">
                    <label>{T('Publisher')}</label>
                    <Select
                      value={filter.publisherSelected}
                      options={filter.publisherList}
                      onChange={option => this.onFilterChange(option, 'publisherSelected')}
                      isMulti
                    />
                  </div>

                  <div className="form-group mb-0">
                    <label>{T('Rebate Type')}</label>
                    <Select
                      value={filter.rebateTypeSelected}
                      options={filter.rebateTypeList}
                      onChange={option => this.onFilterChange(option, 'rebateTypeSelected')}
                      isMulti={false}
                    />
                    <Validation Value={filter.rebateTypeSelected} Check={validation} />
                  </div>
                  <div className="form-group mb-0">
                    <label>{T('Date Range')}</label>
                    <div>
                      <DateRangePicker
                        className="date-range-picker form-control"
                        onChange={this.onDateChange}
                        value={[filter.beginDate, filter.endDate]}
                        format="dd.MM.yyyy"
                      />
                    </div>
                  </div>
                  <div className="form-group mb-0 pp-switch">
                    <label>{T('Test')}</label>
                    <div>
                      <input
                        type="checkbox"
                        id="test"
                        disabled={!filter.isTested}
                        switch="dark"
                        checked={filter.test}
                        onChange={() => this.setState(prevState => ({
                          filter: {
                            ...prevState.filter,
                            test: !filter.test,
                          },
                        }))}
                      />
                      <label htmlFor="test" data-on-label={T('On')} data-off-label={T('Off')} className="mt-2 mb-1" />
                    </div>
                  </div>
                  <div className="form-group mb-0">
                    <label>&nbsp;</label>
                    <button type="button" className="btn btn-dark bg-dark btn-block" onClick={() => this.onCalculate()}>Calculate</button>
                  </div>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-md-2">
                  <div className="form-group ml-3">
                    <p>
                      <b>{T('Trade Count')}</b>
                      <span className="mr-1 ml-1">:</span>
                      <span>{result.tradeCount}</span>
                    </p>
                    <p>
                      <b>{T('Total Rebate')}</b>
                      <span className="mr-1 ml-1">:</span>
                      <span title={result.totalRebateUSD}>{result.totalRebateUSD.toFixed(4)}</span>
                      <span className="ml-1">USD</span>
                    </p>
                    <p>
                      <b>{T('Elapsed')}</b>
                      <span className="mr-1 ml-1">:</span>
                      <span>{result.elapsed}</span>
                      <span className="ml-1">sn</span>
                    </p>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group ml-3">
                    <p>
                      <b>{T('Exist Trade Count')}</b>
                      <span className="mr-1 ml-1">:</span>
                      <span>{result.existTradeCount}</span>
                    </p>
                    <p>
                      <b>{T('Exist Total Rebate')}</b>
                      <span className="mr-1 ml-1">:</span>
                      <span title={result.existTotalRebateUSD}>{result.existTotalRebateUSD.toFixed(4)}</span>
                      <span className="ml-1">USD</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <DataTableExtensions columns={dataTable.columns} data={dataTable.rows} export print>
                <DataTable
                  className="tbl-admin-symbol"
                  noHeader
                  defaultSortField="id"
                  defaultSortAsc={false}
                  pagination
                  highlightOnHover
                />
              </DataTableExtensions>
            </div>
          </div>
        </Card>
      </>
    );
  }
}
