/* eslint-disable no-extend-native */
// eslint-disable-next-line func-names
const format = function (f, s = ',') {
  let value = this;

  switch (f.toLowerCase()) {
    case 'c':
    case 'currency':
      value = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, s);
      break;
    default:
      break;
  }

  return value;
};

// eslint-disable-next-line func-names
const capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

String.prototype.toCapitalizeCase = capitalize;
String.prototype.format = format;
Number.prototype.format = format;
