/* eslint-disable global-require */
import { Config } from 'react-translator-component';
import { Partner } from './AppConfig';

/**
 * Translator Settings
 */
Config.default = 'en';

Config.list = {
  en: {
    text: 'English',
    icon: require('../assets/locale/flags/en.svg'),
    file: require('../assets/locale/en.js'),
  },
  cn: {
    text: 'Chinese',
    icon: require('../assets/locale/flags/cn.svg'),
    file: require('../assets/locale/cn.js'),
  },
};

/**
 * JS Error Catch
 */
if (!Partner.config.debug) {
  window.onerror = (msg, src, lineno, colno, error) => {
    // eslint-disable-next-line no-console
    console.log(
      'Application Error',
      {
        Message: msg,
        Src: src,
        Line: lineno,
        Column: colno,
        Error: error,
      },
    );

    window.location.href = '/error/500';

    return false;
  };
}
