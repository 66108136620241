import React, { Component } from 'react';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import { T } from 'react-translator-component';
import { BreadCrumb, Card, CompanySelect, PublisherSelect } from '../../components';
import { DataTableColumns } from '../../config/DataTableColumns';
import { Service } from '../../services/index';
import { Partner } from '../../config/AppConfig';

export class Leads extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataTable: {
        columns: DataTableColumns.lead,
        rows: [],
      },
      formData: {
        id: Partner.utilities.publisher(),
        publisherId: -1,
        companyId: -1,
      },
    };
  }

  componentDidMount() {
    this.getLeadList();
  }

  onFilter() {
    this.getLeadList();
  }

  getLeadList() {
    const { formData } = this.state;

    Service.User.LeadList(formData).then(response => {
      if (response) {
        const { dataTable } = this.state;
        dataTable.rows = response;
        this.setState(dataTable);
      }
    });
  }

  render() {
    const { dataTable, formData } = this.state;
    return (
      <>
        <BreadCrumb Title="Leads" Description="Please select your company and publisher to check lead list." />
        <Card>
          <div className="row mb-3">
            <div className="col-md-2">
              <div className="form-group mb-0">
                <label>{T('Company')}</label>
                <CompanySelect onChange={
                  option => this.setState(prevState => ({
                    formData: {
                      ...prevState.formData,
                      companyId: option.value,
                    },
                  }))
                }
                />
              </div>
            </div>
            <div className="col-md-2">
              <div className="form-group mb-0">
                <label>{T('Publisher')}</label>
                <PublisherSelect
                  onChange={
                    option => this.setState(prevState => ({
                      formData: {
                        ...prevState.formData,
                        publisherId: option.value,
                      },
                    }))
                  }
                  company={formData.companyId}
                />
              </div>
            </div>
            <div className="col-md-2">
              <div className="form-group mb-0">
                <label>&nbsp;</label>
                <button type="button" className="btn btn-dark bg-dark btn-block" onClick={() => this.onFilter()}>{T('Filter')}</button>
              </div>
            </div>
          </div>
          <DataTableExtensions columns={dataTable.columns()} data={dataTable.rows} export={false} print={false}>
            <DataTable
              className="tbl-lead"
              noHeader
              defaultSortField="bossId"
              defaultSortAsc={false}
              pagination
              highlightOnHover
            />
          </DataTableExtensions>
        </Card>
      </>
    );
  }
}
