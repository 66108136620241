import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { T } from 'react-translator-component';
import { Partner } from '../config/AppConfig';


export const CompanySelect = props => {
  const [company, setCompany] = useState({ value: -1, label: T('All') });

  const companyList = [{ value: -1, label: T('All') }].concat(Partner.utilities.companyList().map(item => ({ value: item.companyId, label: item.companyName })));

  const { onChange } = props;

  return (
    <Select
      id="company"
      value={company}
      onChange={option => {
        setCompany(option);
        onChange(option);
      }}
      options={companyList}
      isMulti={false}
      name="company"
    />
  );
};

CompanySelect.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export const PublisherSelect = props => {
  const [publisher, setPublisher] = useState({ value: -1, label: T('All') });

  const { onChange, company } = props;

  let publisherList = Partner.utilities.user().publisherAccount;

  if (company !== -1) {
    publisherList = publisherList.filter(item => item.companyId === company);
  }

  publisherList = [{ value: -1, label: T('All') }].concat(publisherList.map(item => ({ value: item.publisherId, label: item.publisherId })));

  return (
    <Select
      id="publisher"
      value={publisher}
      onChange={option => {
        setPublisher(option);
        onChange(option);
      }}
      options={publisherList}
      isMulti={false}
      name="publisher"
    />
  );
};

PublisherSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  company: PropTypes.number,
};

PublisherSelect.defaultProps = {
  company: -1,
};
