import DataTableExtensions from 'react-data-table-component-extensions';
import DataTable from 'react-data-table-component';
import React, { Component } from 'react';
import Select from 'react-select';
import ValidForm from 'react-valid-form-component';
import { T } from 'react-translator-component';
import { BreadCrumb, Card, Modal, ErrorMessage, Validation } from '../../components';
import { DataTableColumns } from '../../config/DataTableColumns';
import { Service } from '../../services';
import { Partner } from '../../config/AppConfig';

export class UserGroup extends Component {
  constructor(props) {
    super(props);

    const pageConfig = 'userGroup';

    this.state = {
      dataTable: {
        columns: DataTableColumns.admin[pageConfig],
        rows: [],
      },
      modal: false,
      url: Partner.service.admin[pageConfig],
      validation: {
        error: false,
        message: '',
        checkValid: false,
      },
      data: {},
      filter: {
        companySelected: { label: 'All', value: '-1' },
        companyList: [{ label: 'All', value: '-1' }],
      },
      list: {
        company: [],
        status: [{ value: true, label: T('Active') }, { value: false, label: T('Passive') }],
        userGroupType: new Map(),
        userGroup: new Map(),
      },
      spread: {
        data: [],
        modal: false,
        url: Partner.service.admin.spread,
        validation: {
          error: false,
          message: '',
        },
      },
    };

    this.onEdit = this.onEdit.bind(this);
    this.onEditSpread = this.onEditSpread.bind(this);
    this.tableRows = null;
  }

  componentDidMount() {
    this.getTable();
  }

  onChangeSelect(val, key) {
    const { data } = this.state;
    data[key] = val;
    if (key === 'companySelected') {
      data.companyId = val.value;
    }
    this.setState({
      data,
    });
  }

  onChangeSpread(val, id) {
    const { spread } = this.state;
    spread.data.forEach((value, index) => {
      if (value.id === id) {
        spread.data[index].amount = val;
      }
    });
    this.setState({
      spread,
    });
  }

  onEdit(id) {
    const { url, list } = this.state;
    let { data } = this.state;
    Service.Admin.Detail({ id }, url).then(response => {
      if (response && response.error === false) {
        data = response.data;

        const [status] = list.status.filter(f => f.value === data.status);
        data.statusSelected = status;
        const [userGroupType] = list.userGroupType.get(data.companyId).filter(f => f.value === data.userGroupTypeId);
        data.userGroupTypeSelected = userGroupType;

        const [company] = list.company.filter(f => f.value === data.companyId);
        data.companySelected = company;

        const [userGroup] = list.userGroup.get(data.companyId).filter(f => f.value === data.group);
        data.userGroupSelected = userGroup;
        this.setState({
          data,
          validation: {
            error: false,
            message: '',
            checkValid: false,
          },
          modal: true,
        });
      }
    });
  }

  onSave(data, valid) {
    const { url } = this.state;
    const { data: thisData } = this.state;
    this.checkValid();

    if (valid) {
      if (thisData.companySelected && thisData.statusSelected && thisData.userGroupTypeSelected && thisData.userGroupSelected) {
        const postData = data;
        postData.companyId = thisData.companySelected.value;
        postData.status = thisData.statusSelected.value;
        postData.userGroupTypeId = thisData.userGroupTypeSelected.value;
        postData.group = thisData.userGroupSelected.value;

        Service.Admin.Save(postData, url).then(response => {
          if (response) {
            if (response.error === false) {
              this.setState({
                modal: false,
                data: {},
              });
              this.getTable();
            } else {
              this.setState({
                validation: {
                  error: response.error,
                  message: response.message,
                  checkValid: false,
                },
              });
            }
          }
        });
      }
    }
  }

  onEditSpread(id, userGroup) {
    const { spread } = this.state;

    Service.Admin.Detail({ userGroupId: id }, spread.url).then(response => {
      if (response && response.error === false) {
        this.setState(prevState => ({
          spread: {
            ...prevState.spread,
            data: response.data,
            userGroup,
            userGroupId: id,
            modal: true,
            validation: {
              error: false,
              message: '',
            },
          },
        }));
      }
    });
  }

  onSaveSpread() {
    const { spread } = this.state;
    Service.Admin.Save(spread.data, spread.url).then(response => {
      if (response) {
        if (response.error === false) {
          this.setState(prevState => ({
            spread: {
              ...prevState.spread,
              modal: false,
            },
          }));
        } else {
          this.setState(prevState => ({
            spread: {
              ...prevState.spread,
              validation: {
                error: response.error,
                message: response.message,
                checkValid: false,
              },
            },
          }));
        }
      }
    });
  }

  onNew() {
    this.setState({
      modal: true,
      data: {},
      validation: {
        error: false,
        message: '',
        checkValid: false,
      },
    });
  }

  getTable() {
    const { dataTable, url, filter, list } = this.state;
    Service.Admin.List(url).then(response => {
      if (response && response.error === false) {
        dataTable.rows = response.data.data;
        list.company = response.data.common.company;

        response.data.common.userGroupType.forEach(item => {
          list.userGroupType.set(item.companyId, item.list);
        });

        response.data.common.userGroup.forEach(item => {
          list.userGroup.set(item.companyId, item.list);
        });

        filter.companyList = filter.companyList.concat(response.data.common.company);

        this.setState({
          dataTable,
          filter,
          list,
        });

        this.tableRows = response.data.data;
      }
    });
  }

  checkValid() {
    this.setState(prevState => ({
      validation: {
        ...prevState.validation,
        checkValid: true,
      },
    }));
  }

  render() {
    const { dataTable, data, validation, modal, filter, list, spread } = this.state;
    return (
      <>
        <BreadCrumb Title="User Group" Description="Lorem ipsum dolor sit amet" />
        <Card>
          <Modal Title={T('Edit User Group')} Show={modal} onClose={() => this.setState({ modal: false, data: {} })}>
            <div className="row mt-1 mb-1">
              <p className="text-muted mb-2 ml-1 col-md-12 font-12">{T('User Group')}</p>
              <div className="col-md-12">
                <ValidForm className="form-horizontal m-t-10" nosubmit novalid onSubmit={(form, formData, valid) => this.onSave(formData, valid)} data={data}>
                  <div className="form-group">
                    <div className="row">
                      <ErrorMessage Validation={validation} />
                    </div>
                  </div>
                  <div className="form-group">
                    <input type="hidden" className="form-control" id="id" name="id" />
                  </div>
                  <div className="form-group row ml-1 mr-1">
                    <label htmlFor="login" className="col-sm-4 col-form-label">{T('Company')}</label>
                    <div className="col-sm-8">
                      <Select
                        value={data.companySelected}
                        options={list.company}
                        onChange={option => this.onChangeSelect(option, 'companySelected')}
                        isMulti={false}
                      />
                      <Validation Value={data.statusSelected} Check={validation.checkValid} />
                    </div>
                  </div>
                  <div className="form-group row ml-1 mr-1">
                    <label htmlFor="login" className="col-sm-4 col-form-label">{T('Group')}</label>
                    <div className="col-sm-8">
                      <Select
                        value={data.userGroupSelected}
                        options={list.userGroup.get(data.companyId)}
                        onChange={option => this.onChangeSelect(option, 'userGroupSelected')}
                        isMulti={false}
                      />
                      <Validation Value={data.userGroupSelected} Check={validation.checkValid} />
                    </div>
                  </div>
                  <div className="form-group row ml-1 mr-1">
                    <label htmlFor="login" className="col-sm-4 col-form-label">{T('User Group Type')}</label>
                    <div className="col-sm-8">
                      <Select
                        value={data.userGroupTypeSelected}
                        options={list.userGroupType.get(data.companyId)}
                        onChange={option => this.onChangeSelect(option, 'userGroupTypeSelected')}
                        isMulti={false}
                      />
                      <Validation Value={data.userGroupTypeSelected} Check={validation.checkValid} />
                    </div>
                  </div>
                  <div className="form-group row ml-1 mr-1">
                    <label htmlFor="login" className="col-sm-4 col-form-label">{T('Status')}</label>
                    <div className="col-sm-8">
                      <Select
                        value={data.statusSelected}
                        options={list.status}
                        onChange={option => this.onChangeSelect(option, 'statusSelected')}
                        isMulti={false}
                      />
                      <Validation Value={data.statusSelected} Check={validation.checkValid} />
                    </div>
                  </div>
                  <div className="form-group row m-t-20 mr-1">
                    <div className="col-sm-12 text-right">
                      <button type="submit" className="btn btn-success w-md waves-effect waves-light">{T('Save')}</button>
                    </div>
                  </div>
                </ValidForm>
              </div>
            </div>
          </Modal>
          <Modal Title={T('Edit Spread')} Show={spread.modal} onClose={() => this.setState(prevState => ({ spread: { ...prevState.spread, modal: false } }))} isScrollable>
            <div className="row mt-1 mb-1">
              <p className="text-muted mb-2 col-md-12 font-12">{spread.userGroup}</p>
              <div className="col-md-12">
                <div className="form-group">
                  <div className="row">
                    <ErrorMessage Validation={spread.validation} />
                  </div>
                </div>
                <div className="row">
                  {spread.data.map(m => (
                    <div className="col-md-3">
                      <div className="form-group">
                        <input type="hidden" className="form-control" value={m.id} />
                      </div>
                      <div className="form-group">
                        <label htmlFor="login" className="col-form-label">{m.symbolSecurityName}</label>
                        <input type="text" className="form-control" value={m.amount} onChange={e => this.onChangeSpread(e.target.value, m.id)} />
                      </div>
                    </div>
                  ))}
                </div>
                <div className="form-group row m-t-20 mr-1">
                  <div className="col-sm-12 text-right">
                    <button type="button" className="btn btn-success w-md waves-effect waves-light" onClick={() => this.onSaveSpread()}>{T('Save')}</button>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
          <div className="row">
            <div className="col-md-12 mb-5">
              <div className="col-md-2">
                <div className="form-group mb-0">
                  <label>{T('Company')}</label>
                  <Select
                    value={filter.companySelected}
                    options={filter.companyList}
                    onChange={option => {
                      if (option.value === '-1') {
                        dataTable.rows = this.tableRows;
                      } else {
                        dataTable.rows = this.tableRows.filter(f => f.companyId === option.value);
                      }

                      filter.companySelected = option;

                      this.setState({ filter, dataTable });
                    }}
                    isMulti={false}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <button type="button" className="btn btn-info position-absolute add-new-button tabless" onClick={() => this.onNew()}>
                <i className="fas fa-plus mr-2" />
                {T('Add New')}
              </button>
              <DataTableExtensions columns={dataTable.columns(this.onEdit, this.onEditSpread)} data={dataTable.rows} export={false} print={false}>
                <DataTable
                  className="tbl-admin-symbol"
                  noHeader
                  defaultSortField="id"
                  defaultSortAsc={false}
                  pagination
                  highlightOnHover
                />
              </DataTableExtensions>
            </div>
          </div>
        </Card>
      </>
    );
  }
}
