import React, { Component } from 'react';
import DataTable from 'react-data-table-component';
import { T } from 'react-translator-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import { isArray } from 'util';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { BreadCrumb, Card, CompanySelect, PublisherSelect } from '../../components';
import { DataTableColumns } from '../../config/DataTableColumns';
import { Service } from '../../services/index';
import { Modal } from '../../components/Modal';
import { Partner } from '../../config/AppConfig';


export class Customers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataTable: {
        columns: DataTableColumns.customers,
        rows: [],
      },
      trades: {
        login: 0,
        companyId: 0,
        modal: false,
        dataTable: {
          columns: DataTableColumns.trade,
          rows: [],
        },
        beginDate: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 1),
        endDate: new Date(),
      },
      formData: {
        id: Partner.utilities.publisher(),
        publisherId: -1,
        companyId: -1,
      },
    };
    this.onTrade = this.onTrade.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
    this.onFilterTrade = this.onFilterTrade.bind(this);
  }

  componentDidMount() {
    this.getCustomerList();
  }

  onTrade(login, companyId) {
    const { trades } = this.state;
    Service.User.TradeList({ companyId, login, beginDate: trades.beginDate, endDate: trades.endDate }).then(response => {
      if (response) {
        trades.dataTable.rows = response;
        trades.login = login;
        trades.companyId = companyId;
        trades.modal = true;
        this.setState({
          trades,
        });
      }
    });
  }

  onCloseModal() {
    const { trades } = this.state;
    trades.modal = false;
    this.setState({
      trades,
    });
  }

  onDateChange(date) {
    const { trades } = this.state;
    if (date !== null && isArray(date)) {
      const [one, two] = date;
      trades.beginDate = one;
      trades.endDate = two;
      this.setState({
        trades,
      });
    }
  }

  onFilterTrade() {
    const { trades } = this.state;
    Service.User.TradeList({ companyId: trades.companyId, login: trades.login, beginDate: trades.beginDate, endDate: trades.endDate }).then(response => {
      if (response) {
        trades.dataTable.rows = response;
        trades.modal = true;
        this.setState({
          trades,
        });
      }
    });
  }

  onFilter() {
    this.getCustomerList();
  }

  getCustomerList() {
    const { formData } = this.state;
    Service.User.CustomerList(formData).then(response => {
      if (response) {
        const { dataTable } = this.state;
        dataTable.rows = response;
        this.setState(dataTable);
      }
    });
  }


  render() {
    const { dataTable, trades, formData } = this.state;
    return (
      <>
        <Modal Show={trades.modal} onClose={this.onCloseModal} isScrollable Title={`${T('Trades')} - ${trades.login}`}>
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-4">
                  <DateRangePicker
                    className="date-range-picker form-control"
                    onChange={this.onDateChange}
                    value={[trades.beginDate, trades.endDate]}
                    format="dd.MM.yyyy"
                  />
                </div>
                <div className="col-md-4">
                  <button className="btn btn-dark" type="button" onClick={e => this.onFilterTrade(e)}>
                    {T('Filter')}
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <DataTable
                columns={trades.dataTable.columns()}
                data={trades.dataTable.rows}
                noHeader
                defaultSortField="ticket"
                defaultSortAsc={false}
                pagination
                highlightOnHover
                className="tbl-customer-trade"
              />
            </div>
          </div>
        </Modal>
        <BreadCrumb Title="Customers" Description="Please select your company and publisher(IB ID number) to check customer list." />
        <Card>
          <div className="row mb-3">
            <div className="col-md-2">
              <div className="form-group mb-0">
                <label>{T('Company')}</label>
                <CompanySelect onChange={
                  option => this.setState(prevState => ({
                    formData: {
                      ...prevState.formData,
                      companyId: option.value,
                    },
                  }))
                }
                />
              </div>
            </div>
            <div className="col-md-2">
              <div className="form-group mb-0">
                <label>{T('Publisher')}</label>
                <PublisherSelect
                  onChange={
                    option => this.setState(prevState => ({
                      formData: {
                        ...prevState.formData,
                        publisherId: option.value,
                      },
                    }))
                  }
                  company={formData.companyId}
                />
              </div>
            </div>
            <div className="col-md-2">
              <div className="form-group mb-0">
                <label>&nbsp;</label>
                <button type="button" className="btn btn-dark bg-dark btn-block" onClick={() => this.onFilter()}>{T('Filter')}</button>
              </div>
            </div>
          </div>
          <DataTableExtensions columns={dataTable.columns(this.onTrade)} data={dataTable.rows} export={false} print={false}>
            <DataTable
              className="tbl-customer"
              noHeader
              defaultSortField="accountNumber"
              defaultSortAsc={false}
              pagination
              highlightOnHover
            />
          </DataTableExtensions>
        </Card>
      </>
    );
  }
}
