module.exports = [
  { value: -1, label: 'Ülke Seçiniz' },
  { value: 1, label: 'United States' },
  { value: 7, label: 'Russia' },
  { value: 20, label: 'Egypt' },
  { value: 27, label: 'South Africa' },
  { value: 30, label: 'Greece' },
  { value: 31, label: 'Netherlands' },
  { value: 32, label: 'Belgium' },
  { value: 33, label: 'France' },
  { value: 34, label: 'Spain' },
  { value: 36, label: 'Hungary' },
  { value: 39, label: 'italy' },
  { value: 40, label: 'Romania' },
  { value: 41, label: 'Switzerland' },
  { value: 43, label: 'Austria' },
  { value: 44, label: 'United Kingdom' },
  { value: 45, label: 'Denmark' },
  { value: 46, label: 'Sweden' },
  { value: 47, label: 'Norway' },
  { value: 48, label: 'Poland' },
  { value: 49, label: 'Germany' },
  { value: 51, label: 'Peru' },
  { value: 52, label: 'Mexico' },
  { value: 53, label: 'Cuba' },
  { value: 54, label: 'Argentina' },
  { value: 55, label: 'Brazil' },
  { value: 56, label: 'Chile' },
  { value: 57, label: 'Colombia' },
  { value: 58, label: 'Venezuela' },
  { value: 60, label: 'Malaysia' },
  { value: 61, label: 'Australia' },
  { value: 62, label: 'Indonesia' },
  { value: 63, label: 'The Philippines' },
  { value: 64, label: 'New Zealand' },
  { value: 65, label: 'Singapore' },
  { value: 66, label: 'Thailand' },
  { value: 81, label: 'Japan' },
  { value: 82, label: 'Guney Kore' },
  { value: 84, label: 'Vietnam' },
  { value: 86, label: 'China' },
  { value: 90, label: 'Türkiye' },
  { value: 91, label: 'india' },
  { value: 92, label: 'Pakistan' },
  { value: 93, label: 'Afghanistan' },
  { value: 94, label: 'Sri Lanka' },
  { value: 95, label: 'Myanmar' },
  { value: 98, label: 'iran' },
  { value: 100, label: 'Canada' },
  { value: 212, label: 'Morocco' },
  { value: 213, label: 'Algeria' },
  { value: 216, label: 'Tunusia' },
  { value: 218, label: 'Libya' },
  { value: 220, label: 'Gambia' },
  { value: 221, label: 'Senegal' },
  { value: 222, label: 'Mauritania' },
  { value: 223, label: 'Mali' },
  { value: 224, label: 'Guinea' },
  { value: 225, label: "Cote D'ivoire" },
  { value: 226, label: 'Burkina Faso' },
  { value: 227, label: 'Niger' },
  { value: 228, label: 'Togo' },
  { value: 229, label: 'Benin' },
  { value: 230, label: 'Mauritius' },
  { value: 231, label: 'Liberia' },
  { value: 232, label: 'Sierra leone' },
  { value: 233, label: 'Ghana' },
  { value: 234, label: 'Nigeria' },
  { value: 235, label: 'Chad' },
  { value: 236, label: 'Central African Republic' },
  { value: 237, label: 'Cameroon' },
  { value: 238, label: 'Cape Verde' },
  { value: 239, label: 'Sao Tome&Principe' },
  { value: 240, label: 'Equatorial Guinea' },
  { value: 241, label: 'Gabon' },
  { value: 242, label: 'Congo' },
  { value: 243, label: 'Congo, the Drc' },
  { value: 244, label: 'Angola' },
  { value: 245, label: 'Guinea-Bissau' },
  { value: 246, label: 'Diego Garsia' },
  { value: 248, label: 'Seychelles' },
  { value: 249, label: 'Sudan' },
  { value: 250, label: 'Rwanda' },
  { value: 251, label: 'Ethiopia' },
  { value: 252, label: 'Somalia' },
  { value: 253, label: 'Djibouti' },
  { value: 254, label: 'Kenya' },
  { value: 255, label: 'Tanzania' },
  { value: 256, label: 'Uganda' },
  { value: 257, label: 'Burundi' },
  { value: 258, label: 'Finland' },
  { value: 260, label: 'Zambia' },
  { value: 261, label: 'Madagascar' },
  { value: 262, label: 'Mayotte Adalari' },
  { value: 263, label: 'Zimbabwe' },
  { value: 264, label: 'Namibia' },
  { value: 265, label: 'Malawi' },
  { value: 266, label: 'Lesotho' },
  { value: 267, label: 'Botswana' },
  { value: 268, label: 'Swaziland' },
  { value: 269, label: 'Comoros' },
  { value: 290, label: 'St.Piyer & Mikelon' },
  { value: 291, label: 'Eritrea' },
  { value: 297, label: 'Aruba' },
  { value: 298, label: 'Faroe Adalari' },
  { value: 299, label: 'Gronland' },
  { value: 350, label: 'Cebelitarik' },
  { value: 351, label: 'Portugal' },
  { value: 352, label: 'Luxembourg' },
  { value: 353, label: 'ireland' },
  { value: 354, label: 'iceland' },
  { value: 355, label: 'Albania' },
  { value: 356, label: 'Malta' },
  { value: 357, label: 'Cyprus' },
  { value: 358, label: 'Finland' },
  { value: 359, label: 'Bulgaria' },
  { value: 370, label: 'Lithuania' },
  { value: 371, label: 'Latvia' },
  { value: 372, label: 'Estonia' },
  { value: 373, label: 'Moldova' },
  { value: 374, label: 'Armenia' },
  { value: 375, label: 'Belarus' },
  { value: 376, label: 'Andorra' },
  { value: 377, label: 'Monaco' },
  { value: 378, label: 'San marino' },
  { value: 379, label: 'Vatican City' },
  { value: 380, label: 'Ukraine' },
  { value: 381, label: 'Serbia' },
  { value: 382, label: 'Montenegro' },
  { value: 385, label: 'Croatia' },
  { value: 386, label: 'Slovenia' },
  { value: 387, label: 'Bosnia and Herzegowina' },
  { value: 389, label: 'Macedonia' },
  { value: 420, label: 'Czech Republic' },
  { value: 421, label: 'Slovakia' },
  { value: 423, label: 'Liechtenstein' },
  { value: 500, label: 'Falkland Adl.' },
  { value: 501, label: 'Belize' },
  { value: 502, label: 'Guatemala' },
  { value: 503, label: 'El salvador' },
  { value: 504, label: 'Honduras' },
  { value: 505, label: 'Nicaragua' },
  { value: 506, label: 'Costa rica' },
  { value: 507, label: 'Panama' },
  { value: 508, label: 'Turks & Caicos adl.' },
  { value: 509, label: 'Haiti' },
  { value: 590, label: 'Guadalup' },
  { value: 591, label: 'Bolivia' },
  { value: 592, label: 'Guyana' },
  { value: 593, label: 'Ecuador' },
  { value: 594, label: 'Fransiz Guyanasi' },
  { value: 595, label: 'Paraguay' },
  { value: 596, label: 'Martinik' },
  { value: 597, label: 'Surinama' },
  { value: 598, label: 'Uruguay' },
  { value: 599, label: 'Hollanda Antilleri' },
  { value: 670, label: 'East Timor' },
  { value: 672, label: 'Norfolk Adalari' },
  { value: 673, label: 'Brunei Darussalam' },
  { value: 674, label: 'Nauru' },
  { value: 675, label: 'Papua New Guinea' },
  { value: 676, label: 'Tonga' },
  { value: 677, label: 'Solomon Islands' },
  { value: 678, label: 'Vanuatu' },
  { value: 679, label: 'Fiji' },
  { value: 680, label: 'Palau' },
  { value: 681, label: 'Yeni Hebritler' },
  { value: 683, label: 'Niue Adalari' },
  { value: 685, label: 'Bati Samoa' },
  { value: 686, label: 'Kiribati' },
  { value: 688, label: 'Tuvalu' },
  { value: 689, label: 'Fransiz Polinezyasi' },
  { value: 691, label: 'Micronesia, Federated States of' },
  { value: 692, label: 'Marshall Islands' },
  { value: 767, label: 'Dominica' },
  { value: 809, label: 'Dominican Republic' },
  { value: 850, label: 'Kuzey kore' },
  { value: 852, label: 'Hongkong' },
  { value: 853, label: 'Makao' },
  { value: 855, label: 'Cambodia' },
  { value: 856, label: 'Laos' },
  { value: 880, label: 'Bangladesh' },
  { value: 886, label: 'Tawain' },
  { value: 939, label: 'Puerto rico' },
  { value: 960, label: 'Maldives' },
  { value: 961, label: 'Lebanon' },
  { value: 962, label: 'Jordan' },
  { value: 963, label: 'Syria' },
  { value: 964, label: 'iraq' },
  { value: 965, label: 'Kuwait' },
  { value: 966, label: 'Saudi arabia' },
  { value: 967, label: 'Yemen' },
  { value: 968, label: 'Oman' },
  { value: 970, label: 'Filistin' },
  { value: 971, label: 'United Arab Emirates' },
  { value: 972, label: 'israel' },
  { value: 973, label: 'Bahrain' },
  { value: 974, label: 'Qatar' },
  { value: 975, label: 'Bhutan' },
  { value: 976, label: 'Mongolia' },
  { value: 977, label: 'Nepal' },
  { value: 992, label: 'Tajikistan' },
  { value: 993, label: 'Turkmenistan' },
  { value: 994, label: 'Azerbaijan' },
  { value: 995, label: 'Georgia' },
  { value: 996, label: 'Kyrgyzstan' },
  { value: 998, label: 'Uzbekistan' },
  { value: 1242, label: 'Bahamas' },
  { value: 1246, label: 'Barbados' },
  { value: 1264, label: 'Anguilla' },
  { value: 1268, label: 'Antigua and Barbuda' },
  { value: 1340, label: 'Vallis Futuna Adalari' },
  { value: 1345, label: 'Cayman Islands' },
  { value: 1441, label: 'Bermuda' },
  { value: 1473, label: 'Grenada' },
  { value: 1649, label: 'U.s. Virjin adasi' },
  { value: 1664, label: 'Montserrat' },
  { value: 1670, label: 'Mariyan Adalari' },
  { value: 1671, label: 'Guam' },
  { value: 1684, label: 'Samoa' },
  { value: 1758, label: 'St. Lucia' },
  { value: 1784, label: 'St. Vincent&the Grenadines' },
  { value: 1808, label: 'Usa(Hawaii)' },
  { value: 1868, label: 'Trinidad&Tobago' },
  { value: 1869, label: 'St. Kitts&Nevis' },
  { value: 1876, label: 'Jamaica' },
  { value: 1907, label: 'Usa(alaska)' },
];
