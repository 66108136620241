import DataTableExtensions from 'react-data-table-component-extensions';
import DataTable from 'react-data-table-component';
import React, { Component } from 'react';
import Select from 'react-select';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { isArray } from 'util';
import { T } from 'react-translator-component';
import { BreadCrumb, Card, Validation } from '../../components';
import { Service } from '../../services';
import { Partner } from '../../config/AppConfig';

export class RebateReport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      validation: false,
      dataTable: {
        columns: [],
        rows: [],
      },
      filter: {
        companyList: [],
        companySelected: undefined,
        beginDate: new Date(),
        endDate: new Date(),
      },
    };

    this.list = {
      publisher: new Map(),
    };

    this.onDateChange = this.onDateChange.bind(this);
    this.onFilter = this.onFilter.bind(this);
  }

  componentDidMount() {
    this.getTable();
  }


  onFilterChange(option, key) {
    const { filter } = this.state;

    filter[key] = option;

    this.setState({
      filter,
    });
  }

  onDateChange(date) {
    const { filter } = this.state;

    if (date !== null && isArray(date)) {
      const [one, two] = date;
      filter.beginDate = one;
      filter.endDate = two;
      this.setState({
        filter,
      });
    }
  }

  onFilter() {
    const { filter, dataTable } = this.state;

    this.setState({
      validation: true,
    });

    if (filter.companySelected) {
      Service.Admin.Report(Partner.service.admin.rebate.report, {
        companyId: filter.companySelected.value,
        beginDate: filter.beginDate,
        endDate: filter.endDate,
      }).then(response => {
        if (response) {
          let tableColumns = [
            {
              name: 'Login',
              selector: 'metaAccount',
              sortable: true,
            },
            {
              name: 'Name',
              selector: 'publisherName',
              sortable: true,
            },
            {
              name: 'Publisher ID',
              selector: 'publisher',
              sortable: true,
            },
          ];
          const rebateCurrencyColumn = [...new Set(response.map(item => Object.keys(item.rebate)).flat())];

          tableColumns = tableColumns.concat(rebateCurrencyColumn.map(item => ({
            name: item,
            selector: `rebate.${item}`,
            sortable: true,
          }))).concat({
            name: 'USD Rebate',
            selector: 'usdAmount',
            sortable: true,
            cell: d => d.usdAmount.toFixed(2),
          });

          response.forEach((item, index) => {
            Object.keys(item.rebate).forEach(key => {
              response[index].rebate[key] = response[index].rebate[key].toFixed(2);
            });
          });

          dataTable.rows = response;
          dataTable.columns = tableColumns;
          this.setState({ dataTable });
        }
      });
    }
  }

  getTable() {
    const { dataTable, filter } = this.state;
    Service.Admin.Filter(Partner.service.admin.rebate.filter).then(response => {
      if (response) {
        filter.companyList = response.company;

        this.setState({
          dataTable,
          filter,
        });
      }
    });
  }

  render() {
    const { dataTable, filter, validation } = this.state;
    return (
      <>
        <BreadCrumb Title="Daily Rebate" Description="Lorem ipsum dolor sit amet" />
        <Card>
          <div className="row mb-4">
            <div className="col-md-12 m-2 mb-4">
              <div className="row">
                <div className="col-md-12 pp-filter-wrapper">
                  <div className="form-group mb-0">
                    <label>{T('Company')}</label>
                    <Select
                      value={filter.companySelected}
                      options={filter.companyList}
                      onChange={option => this.onFilterChange(option, 'companySelected')}
                      isMulti={false}
                    />
                    <Validation Value={filter.companySelected} Check={validation} />
                  </div>
                  <div className="form-group mb-0">
                    <label>{T('Date Range')}</label>
                    <div>
                      <DateRangePicker
                        className="date-range-picker form-control"
                        onChange={this.onDateChange}
                        value={[filter.beginDate, filter.endDate]}
                        format="dd.MM.yyyy"
                      />
                    </div>
                  </div>
                  <div className="form-group mb-0">
                    <label>&nbsp;</label>
                    <button type="button" className="btn btn-dark bg-dark btn-block" onClick={() => this.onFilter()}>Filter</button>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <DataTableExtensions columns={dataTable.columns} data={dataTable.rows} export print exportHeaders>
                <DataTable
                  className="tbl-admin-symbol"
                  noHeader
                  defaultSortField="id"
                  defaultSortAsc={false}
                  highlightOnHover
                />
              </DataTableExtensions>
            </div>
          </div>
        </Card>
      </>
    );
  }
}
