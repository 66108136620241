import DataTableExtensions from 'react-data-table-component-extensions';
import DataTable from 'react-data-table-component';
import React, { Component } from 'react';
import Select from 'react-select';
import ValidForm from 'react-valid-form-component';
import { T } from 'react-translator-component';
import { BreadCrumb, Card, Modal, ErrorMessage, Validation, Confirm } from '../../components';
import { DataTableColumns } from '../../config/DataTableColumns';
import { Service } from '../../services';
import { Partner } from '../../config/AppConfig';

const countryList = require('../../config/constant/Country');
const pageConfig = 'user';

export class User extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataTable: {
        columns: DataTableColumns.admin[pageConfig],
        rows: [],
      },
      modal: false,
      url: Partner.service.admin[pageConfig],
      validation: {
        error: false,
        message: '',
        checkValid: false,
      },
      data: {},
      list: {
        role: [],
        status: [{ value: true, label: T('Active') }, { value: false, label: T('Passive') }],
      },
      publisherId: 0,
      publisherSelect: [],
    };

    this.onEdit = this.onEdit.bind(this);
  }

  componentDidMount() {
    this.getTable();
  }

  onChangeSelect(val, key) {
    const { data } = this.state;
    data[key] = val;

    this.setState({
      data,
    });
  }

  onFilter = () => {
    this.getTable();
  };

  onClear = () => {
    this.setState({
      publisherId: 0,
      publisherSelect: [],
    }, () => {
      this.getTable();
    });
  };

  onEdit(id) {
    const { url, list } = this.state;
    let { data } = this.state;
    Service.Admin.Detail({ id }, url).then(response => {
      if (response && response.error === false) {
        data = response.data;
        const [role] = list.role.filter(f => f.value === data.roleId);
        data.roleSelected = role;
        const [status] = list.status.filter(f => f.value === data.status);
        data.statusSelected = status;
        const [countrySelected] = countryList.filter(f => f.value === data.countryCode);
        data.countrySelected = countrySelected;

        this.setState({
          data,
          modal: true,
          validation: {
            error: false,
            message: '',
            checkValid: false,
          },
        });
      }
    });
  }

  onSave(data, valid) {
    const { url } = this.state;
    const { data: thisData } = this.state;
    this.checkValid();
    if (valid && thisData.roleSelected && thisData.statusSelected) {
      const postData = data;
      postData.roleId = thisData.roleSelected.value;
      postData.status = thisData.statusSelected.value;
      postData.countryCode = thisData.countrySelected ? thisData.countrySelected.value : 0;

      Service.Admin.Save(postData, url).then(response => {
        if (response) {
          if (response.error === false) {
            this.setState({
              modal: false,
              data: {},
            });
            this.getTable();
          } else {
            this.setState({
              validation: {
                error: response.error,
                message: response.message,
                checkValid: false,
              },
            });
          }
        }
      });
    }
  }

  onResetPassword(email) {
    Confirm({
      title: T('Are you sure?'),
      type: { warning: true },
      description: T('You are resetting password!'),
      onConfirm: () => {
        Service.User.RecoverPassword({ email }, true).then(response => {
          if (response) {
            if (response.error) {
              this.setState({
                modal: false,
              });
            } else {
              this.setState({
                validation: {
                  error: response.error,
                  message: T(response.message),
                },
              });
            }
          }
        });
      },
      onCancel: () => {

      },
    });
  }

  onNew() {
    this.setState({
      modal: true,
      data: {},
      validation: {
        error: false,
        message: '',
        checkValid: false,
      },
    });
  }

  getTable() {
    const { dataTable, url, list, data, publisherSelect, publisherId } = this.state;
    if (publisherId > 0 && publisherId != null) {
      url.list = url.list + '?publisherId=' + publisherSelect.value;
    }
    else {
      let d = url.list.split('?');
      url.list = d[0];
    }

    Service.Admin.List(url).then(response => {
      if (response && response.error === false) {
        dataTable.rows = response.data.data;
        list.role = response.data.common.role;
        data.publisherList = response.data.common.publisherList;
        this.setState({
          dataTable,
          list,
          data,
        });
      }
    });
  }

  checkValid() {
    this.setState(prevState => ({
      validation: {
        ...prevState.validation,
        checkValid: true,
      },
    }));
  }

  render() {
    const { dataTable, data, validation, modal, list } = this.state;
    return (
      <>
        <BreadCrumb Title="User" Description="Lorem ipsum dolor sit amet" />
        <Card>
          <Modal Title={T('Edit User')} Show={modal} onClose={() => this.setState({ modal: false, data: {} })}>
            <div className="row mt-1 mb-1">
              <p className="text-muted mb-2 ml-1 col-md-12 font-12">{T('User')}</p>
              <div className="col-md-12">
                <ValidForm className="form-horizontal m-t-10" nosubmit novalid onSubmit={(form, formData, valid) => this.onSave(formData, valid)} data={data}>
                  <div className="form-group">
                    <div className="row">
                      <ErrorMessage Validation={validation} />
                    </div>
                  </div>
                  <div className="form-group">
                    <input type="hidden" className="form-control" id="id" name="id" />
                  </div>
                  <div className="form-group row ml-1 mr-1">
                    <label htmlFor="login" className="col-sm-4 col-form-label">{T('Firstname')}</label>
                    <div className="col-sm-8">
                      <input type="text" className="form-control" id="firstName" name="firstName" placeholder={T('Firstname')} required />
                    </div>
                  </div>
                  <div className="form-group row ml-1 mr-1">
                    <label htmlFor="login" className="col-sm-4 col-form-label">{T('Lastname')}</label>
                    <div className="col-sm-8">
                      <input type="text" className="form-control" id="lastName" name="lastName" placeholder={T('Lastname')} required />
                    </div>
                  </div>
                  <div className="form-group row ml-1 mr-1">
                    <label htmlFor="login" className="col-sm-4 col-form-label">{T('Email')}</label>
                    <div className="col-sm-8">
                      <input type="text" className="form-control" id="email" name="email" placeholder={T('Email')} required />
                    </div>
                  </div>
                  <div className="form-group row ml-1 mr-1">
                    <label htmlFor="login" className="col-sm-4 col-form-label">{T('Country')}</label>
                    <div className="col-sm-8">
                      <Select
                        value={data.countrySelected}
                        options={countryList}
                        onChange={option => this.onChangeSelect(option, 'countrySelected')}
                        isMulti={false}
                      />
                    </div>
                  </div>
                  <div className="form-group row ml-1 mr-1">
                    <label htmlFor="login" className="col-sm-4 col-form-label">{T('Status')}</label>
                    <div className="col-sm-8">
                      <Select
                        value={data.statusSelected}
                        options={list.status}
                        onChange={option => this.onChangeSelect(option, 'statusSelected')}
                        isMulti={false}
                      />
                      <Validation Value={data.statusSelected} Check={validation.checkValid} />
                    </div>
                  </div>
                  <div className="form-group row ml-1 mr-1">
                    <label htmlFor="login" className="col-sm-4 col-form-label">{T('Role')}</label>
                    <div className="col-sm-8">
                      <Select
                        value={data.roleSelected}
                        options={list.role}
                        onChange={option => this.onChangeSelect(option, 'roleSelected')}
                        isMulti={false}
                      />
                      <Validation Value={data.roleSelected} Check={validation.checkValid} />
                    </div>
                  </div>
                  <div className="form-group row m-t-20 mr-1">
                    {data.id && (
                      <div className="col-sm-3 offset-7 text-right">
                        <button type="button" className="btn btn-primary w-md waves-effect waves-light" onClick={() => this.onResetPassword(data.email)}>{T('Reset Password')}</button>
                      </div>
                    )}
                    <div className={`text-right ${!data.id ? 'offset-9 col-sm-3' : 'col-sm-2'}`}>
                      <button type="submit" className="btn btn-success w-md waves-effect waves-light">{T('Save')}</button>
                    </div>
                  </div>
                </ValidForm>
              </div>
            </div>
          </Modal>
          <div className="row">
            <div className="col-md-12">
              <button type="button" className="btn btn-info position-absolute add-new-button tabless" onClick={() => this.onNew()}>
                <i className="fas fa-plus  mr-2" />
                {T('Add New')}
              </button>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-xl-2">
              <Select
                value={this.state.publisherSelect}
                options={data.publisherList}
                onChange={option => { this.setState({ publisherSelect: option, publisherId: option.value }) }}
                isMulti={false}
              />
            </div>
            <div className="col-xl-1 filter-button-css">
              <button type="button" className="btn btn-primary" onClick={() => this.onFilter()}><i className="fa fa-search"></i> Filter</button>
            </div>
            <div className="col-xl-2">
              <button type="button" className="btn btn-warning" onClick={() => this.onClear()}><i className="fa fa-times"></i> Clear</button>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-md-12">
              <DataTableExtensions
                columns={dataTable.columns(this.onEdit, id => {
                  localStorage.setItem('publisher', id);
                  document.location.href = '/admin/user-detail';
                })}
                data={dataTable.rows}
                export
                print={false}
              >
                <DataTable
                  className="tbl-admin-symbol"
                  noHeader
                  defaultSortField="id"
                  defaultSortAsc={false}
                  pagination
                  highlightOnHover
                />
              </DataTableExtensions>
            </div>
          </div>
        </Card>
      </>
    );
  }
}
