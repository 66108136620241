import React, { Component } from 'react';
import { T } from 'react-translator-component';
import Select from 'react-select';
import { BreadCrumb, Card, PublisherSelect, CompanySelect } from '../../components';
import { Service } from '../../services';
import { Partner } from '../../config/AppConfig';

export class Marketing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      utmSource: '',
      utmMedium: '',
      utmContent: '',
      utmTerm: '',
      utmCampaign: '',
      id: Partner.utilities.publisher(),
      companyId: -1,
      publisherId: -1,
      group: '',
      create: false,
      groupList: [],
      selectedLanguage: {},
    };

    this.onChange = this.onChange.bind(this);
    this.onFilterGroups = this.onFilterGroups.bind(this);
    this.onCopyToClipboard = this.onCopyToClipboard.bind(this);

    this.url = {
      demoUrl: new Map(),
      liveUrl: new Map(),
    };


    this.url.demoUrl.set(1, 'https://www.artsmrkts.com{lang}/campaigns/open-demo-account');
    this.url.demoUrl.set(2, 'https://www.terafx.com{lang}/campaigns/open-demo-account');

    this.url.liveUrl.set(1, 'https://www.artsmrkts.com{lang}/campaigns/open-live-account');
    this.url.liveUrl.set(2, 'https://www.terafx.com{lang}/campaigns/open-live-account');

    this.selectLanguage = [
      {
        value: '',
        label:
  <span>

    <img src="../assets/images/flags/uk-flag.svg" className="img mr-2 flag-img" alt="English" />
    {' '}
            English
    {' '}
  </span>,
      },
      {
        value: '/zh',
        label:
  <span>
    {' '}
    <img src="../assets/images/flags/cn-flag.svg" className="img mr-2 flag-img" alt="中文" />
    {' '}
            中文
    {' '}
  </span>,
      },
      {
        value: '/pl',
        label:
  <span>
    {' '}
    <img src="../assets/images/flags/pl-flag.svg" className="img mr-2 flag-img" alt="Polska" />
    {' '}
            Polska
    {' '}
  </span>,
      },
      {
        value: '/es',
        label:
  <span>
    {' '}
    <img src="../assets/images/flags/es-flag.svg" className="img mr-2 flag-img" alt="Español" />
    {' '}
            Español
    {' '}
  </span>,
      },
      {
        value: '/ar',
        label:
  <span>
    {' '}
    <img src="../assets/images/flags/ar-flag.svg" className="img mr-2 flag-img" alt="العربية" />
    {' '}
            العربية
    {' '}
  </span>,
      },
      {
        value: '/ur',
        label:
  <span>
    {' '}
    <img src="../assets/images/flags/ur-flag.svg" className="img mr-2 flag-img" alt="اردو" />
    {' '}
            اردو
    {' '}
  </span>,
      },
      {
        value: '/th',
        label:
  <span>
    {' '}
    <img src="../assets/images/flags/th-flag.svg" className="img mr-2 flag-img" alt="ไทย" />
    {' '}
            ไทย
    {' '}
  </span>,
      },
    ];
  }

  componentDidMount() {
    this.onFilterGroups();
    this.setState(prevState => ({
      ...prevState,
      selectedLanguage: this.selectLanguage.filter(f => f.value === '')[0],
    }));
  }

  onFilterGroups() {
    const { id, companyId, publisherId } = this.state;
    Service.User.GroupList({ id, companyId, publisherId }).then(response => {
      if (response) {
        this.setState({
          groupList: response.map(item => ({ value: item, label: item })),
        });
      }
    });
  }

  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  onCopyToClipboard(text) {
    const textarea = document.createElement('textarea');
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
  }

  render() {
    const { utmSource, utmMedium, utmContent, utmTerm, utmCampaign, create, companyId, publisherId, group, groupList, selectedLanguage } = this.state;
    const getUrl = type => {
      const url = (this.url[type].get(companyId) || '');
      const publisher = (publisherId !== -1 ? `?publisher=${publisherId}` : '');
      const openGroup = (group ? `&group=${group}` : '');
      const source = (utmSource ? `&utm_source=${utmSource}` : '');
      const medium = (utmMedium ? `&utm_medium=${utmMedium}` : '');
      const content = (utmContent ? `&utm_content=${utmContent}` : '');
      const term = (utmContent ? `&utm_term=${utmTerm}` : '');
      const campaign = (utmCampaign ? `&utm_campaign=${utmCampaign}` : '');

      return `${url.replace('{lang}', companyId === 1 ? '' : selectedLanguage.value).replace('.com', selectedLanguage.value === '/th' || selectedLanguage.value === '/zh' ? '.net' : '.com')}${publisher}${openGroup}${source}${medium}${content}${term}${campaign}`;
    };

    return (
      <>
        <BreadCrumb Title="Marketing" onlyAdmin Description="Lorem ipsum dolor sit amet" />
        <Card Title="Dear" Description="Please click on 'Create URL' to see the tracking link. <br />Details below might increase the efficiency of our joint campaign.">
          {create && (
            <div className="row ml-1 mb-2">
              <div className="col-sm-8">
                <div className="alert alert-success fade show">
                  <strong className="mr-2">
                    {T('Demo Link')}
                    :
                  </strong>
                  <span>{getUrl('demoUrl')}</span>
                  <button type="button" className="copy-button text-success" title="Copy" onClick={() => this.onCopyToClipboard(getUrl('demoUrl'))}><i className="far fa-copy" /></button>
                </div>
              </div>
              <div className="col-sm-8">
                <div className="alert alert-info fade show">
                  <strong className="mr-2">
                    {T('Live Account Link')}
                    :
                  </strong>
                  <span>{getUrl('liveUrl')}</span>
                  <button type="button" className="copy-button text-info" title="Copy" onClick={() => this.onCopyToClipboard(getUrl('liveUrl'))}><i className="far fa-copy" /></button>
                </div>
              </div>
            </div>
          )}
          <div className="form-group row ml-1">
            <label htmlFor="utm_source" className="col-sm-2 col-form-label">
              {T('Company')}
            </label>
            <div className="col-sm-6">
              <CompanySelect
                onChange={
                  option => this.setState({ companyId: option.value, selectedLanguage: this.selectLanguage.filter(f => f.value === '')[0] })
                }
              />
              <p className="text-muted m-b-15 explanation">
                <span>{T('Required. Select the company where the account will be opened.')}</span>
              </p>
            </div>
          </div>
          <div className="form-group row ml-1">
            <label htmlFor="utm_source" className="col-sm-2 col-form-label">
              {T('Publisher')}
            </label>
            <div className="col-sm-6">
              <PublisherSelect
                onChange={
                  option => this.setState({ publisherId: option.value }, () => this.onFilterGroups())
                }
                company={companyId}
              />
              <p className="text-muted m-b-15 explanation">
                <span>{T('Required. Select the publisher where the account will be attached.')}</span>
              </p>
            </div>
          </div>
          <div className="form-group row ml-1">
            <label htmlFor="utm_source" className="col-sm-2 col-form-label">
              {T('Account Group')}
              <p className="text-muted font-400">
                (
                {T('Meta Group')}
                )
              </p>
            </label>
            <div className="col-sm-6">
              <Select
                id="group"
                value={{ value: group, label: group }}
                onChange={option => {
                  this.setState({ group: option.value });
                }}
                options={groupList}
                isMulti={false}
                name="group"
              />
              <p className="text-muted m-b-15 explanation">
                <span>{T('Required. Select the user group where the account will be opened.')}</span>
              </p>
            </div>
          </div>
          {
            companyId === 2 && (
              <div className="form-group row ml-1">
                <label htmlFor="utm_source" className="col-sm-2 col-form-label">
                  {T('Language')}
                </label>
                <div className="col-sm-6">
                  <Select
                    id="language"
                    value={selectedLanguage}
                    onChange={option => {
                      this.setState(prevState => ({ ...prevState, selectedLanguage: option }));
                    }}
                    options={this.selectLanguage}
                    isMulti={false}
                    name="language"
                  />
                </div>
              </div>
            )
          }
          {/* <div className="form-group form-group-sm row ml-1">
            <label htmlFor="utm_source" className="col-sm-2 col-form-label">
              {T('Campaign Source')}
              <p className="text-muted font-400">(utm_source)</p>
            </label>
            <div className="col-sm-6">
              <input className="form-control form-control-sm" type="text" id="utmSource" name="utmSource" value={utmSource} onChange={e => this.onChange(e)} />
              <p className="text-muted m-b-15 explanation">
                <span>{T('Use utm_source to identify a search engine, newsletter name, or other source.')}</span>
                <code>
                  {T('Example')}
                  : utm_source=google
                </code>
              </p>
            </div>
          </div>
          <div className="form-group form-group-sm row ml-1">
            <label htmlFor="utm_source" className="col-sm-2 col-form-label">
              {T('Campaign medium')}
              <p className="text-muted font-400">(utm_medium)</p>
            </label>
            <div className="col-sm-6">
              <input className="form-control form-control-sm" type="text" id="utmMedium" name="utmMedium" value={utmMedium} onChange={e => this.onChange(e)} />
              <p className="text-muted m-b-15 explanation">
                <span>{T('Use utm_medium to identify a medium such as email or cost-per- click.')}</span>
                <code>
                  {T('Example')}
                  : utm_medium=cpc
                </code>
              </p>
            </div>
          </div>
          <div className="form-group form-group-sm row ml-1">
            <label htmlFor="utm_source" className="col-sm-2 col-form-label">
              {T('Campaign Term')}
              <p className="text-muted font-400">(utm_term)</p>
            </label>
            <div className="col-sm-6">
              <input className="form-control form-control-sm" type="text" id="utmTerm" name="utmTerm" value={utmTerm} onChange={e => this.onChange(e)} />
              <p className="text-muted m-b-15 explanation">
                <span>{T('Used for paid search. Use utm_term to note the keywords for this ad.')}</span>
                <code>
                  {T('Example')}
                  : utm_term=running+shoes
                </code>
              </p>
            </div>
          </div>
          <div className="form-group form-group-sm row ml-1">
            <label htmlFor="utm_source" className="col-sm-2 col-form-label">
              {T('Campaign Content')}
              <p className="text-muted font-400">(utm_content)</p>
            </label>
            <div className="col-sm-6">
              <input className="form-control form-control-sm" type="text" id="utmContent" name="utmContent" value={utmContent} onChange={e => this.onChange(e)} />
              <p className="text-muted m-b-15 explanation">
                <span>{T('Used for A/B testing and content-targeted ads. Use utm_content to differentiate ads or links that point to the same URL.')}</span>
                <code>
                  {T('Examples')}
                  : utm_content=logolink or utm_content=textlink
                </code>
              </p>
            </div>
          </div>
          <div className="form-group form-group-sm row ml-1">
            <label htmlFor="utm_source" className="col-sm-2 col-form-label">
              {T('Campaign Name')}
              <p className="text-muted font-400">(utm_campaign)</p>
            </label>
            <div className="col-sm-6">
              <input className="form-control form-control-sm" type="text" id="utmCampaign" name="utmCampaign" value={utmCampaign} onChange={e => this.onChange(e)} />
              <p className="text-muted m-b-15 explanation">
                <span>{T('Required. Used for keyword analysis. Use utm_campaign to identify a specific product promotion or strategic campaign.')}</span>
                <code>
                  {T('Example')}
                  : utm_campaign=spring_sale
                </code>
              </p>
            </div>
          </div> */}
          <div className="form-group row ml-1 pt-3">
            <label className="col-sm-2 col-form-label" />
            <div className="col-sm-4">
              <button type="button" className="btn btn-info" onClick={() => this.setState({ create: true })}>
                <i className="fas fa-check-circle mr-2" />
                {T('Create URL')}
              </button>
            </div>
          </div>
        </Card>
      </>
    );
  }
}
