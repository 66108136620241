import DataTableExtensions from 'react-data-table-component-extensions';
import DataTable from 'react-data-table-component';
import React, { Component } from 'react';
import ValidForm from 'react-valid-form-component';
import { T } from 'react-translator-component';
import { BreadCrumb, Card, Modal, ErrorMessage } from '../../components';
import { DataTableColumns } from '../../config/DataTableColumns';
import { Service } from '../../services';
import { Partner } from '../../config/AppConfig';

export class Company extends Component {
  constructor(props) {
    super(props);

    const pageConfig = 'company';

    this.state = {
      dataTable: {
        columns: DataTableColumns.admin[pageConfig],
        rows: [],
      },
      modal: false,
      url: Partner.service.admin[pageConfig],
      validation: {
        error: false,
        message: '',
      },
      data: {},
    };

    this.onEdit = this.onEdit.bind(this);
  }

  componentDidMount() {
    this.getTable();
  }

  onEdit(id) {
    const { url } = this.state;
    let { data } = this.state;
    Service.Admin.Detail({ id }, url).then(response => {
      if (response && response.error === false) {
        data = response.data;
        this.setState({
          data,
          validation: {
            error: false,
            message: '',
            checkValid: false,
          },
          modal: true,
        });
      }
    });
  }

  onSave(data, valid) {
    const { url } = this.state;

    if (valid) {
      Service.Admin.Save(data, url).then(response => {
        if (response) {
          if (response.error === false) {
            this.setState({
              modal: false,
              data: {},
            });
            this.getTable();
          } else {
            this.setState({
              validation: {
                error: response.error,
                message: response.message,
                checkValid: false,
              },
            });
          }
        }
      });
    }
  }

  onNew() {
    this.setState({
      modal: true,
      data: {},
      validation: {
        error: false,
        message: '',
        checkValid: false,
      },
    });
  }

  getTable() {
    const { dataTable, url } = this.state;
    Service.Admin.List(url).then(response => {
      if (response && response.error === false) {
        dataTable.rows = response.data;

        this.setState({
          dataTable,
        });
      }
    });
  }

  render() {
    const { dataTable, data, validation, modal } = this.state;
    return (
      <>
        <BreadCrumb Title="Company" Description="Lorem ipsum dolor sit amet" />
        <Card>
          <Modal Title={T('Edit Company')} Show={modal} onClose={() => this.setState({ modal: false, data: {} })}>
            <div className="row mt-1 mb-1">
              <p className="text-muted mb-2 ml-1 col-md-12 font-12">{T('Company')}</p>
              <div className="col-md-12">
                <ValidForm className="form-horizontal m-t-10" nosubmit onSubmit={(form, formData, valid) => this.onSave(formData, valid)} data={data}>
                  <div className="form-group">
                    <div className="row">
                      <ErrorMessage Validation={validation} />
                    </div>
                  </div>
                  <div className="form-group">
                    <input type="hidden" className="form-control" id="id" name="id" />
                  </div>
                  <div className="form-group row ml-1 mr-1">
                    <label htmlFor="login" className="col-sm-4 col-form-label">{T('Title')}</label>
                    <div className="col-sm-8">
                      <input type="text" className="form-control" id="title" name="title" placeholder={T('Title')} required />
                    </div>
                  </div>
                  <div className="form-group row ml-1 mr-1">
                    <label htmlFor="login" className="col-sm-4 col-form-label">{T('Logo')}</label>
                    <div className="col-sm-8">
                      <input type="text" className="form-control" id="logo" name="logo" placeholder={T('Logo')} required />
                    </div>
                  </div>
                  <div className="form-group row ml-1 mr-1">
                    <label htmlFor="login" className="col-sm-4 col-form-label">{T('Common')}</label>
                    <div className="col-sm-8">
                      <textarea type="text" className="form-control" id="common" name="common" rows={7} placeholder={T('Common')} />
                    </div>
                  </div>
                  <div className="form-group row m-t-20 mr-1">
                    <div className="col-sm-12 text-right">
                      <button type="submit" className="btn btn-success w-md waves-effect waves-light">{T('Save')}</button>
                    </div>
                  </div>
                </ValidForm>
              </div>
            </div>
          </Modal>
          <div className="row">
            <div className="col-md-12">
              <button type="button" className="btn btn-info position-absolute add-new-button tabless" onClick={() => this.onNew()}>
                <i className="fas fa-plus  mr-2" />
                {T('Add New')}
              </button>
              <DataTableExtensions columns={dataTable.columns(this.onEdit)} data={dataTable.rows} export={false} print={false}>
                <DataTable
                  className="tbl-admin-symbol"
                  noHeader
                  defaultSortField="id"
                  defaultSortAsc={false}
                  pagination
                  highlightOnHover
                />
              </DataTableExtensions>
            </div>
          </div>
        </Card>
      </>
    );
  }
}
