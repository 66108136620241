import React, { Component } from 'react';
import { T } from 'react-translator-component';
import { isArray } from 'util';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { BreadCrumb, Card, CompanySelect, Collapse, UserHeader, NoData, Symbol, SymbolHeader } from '../../components';
import { Service } from '../../services';
import { Partner } from '../../config/AppConfig';

export class Reports extends Component {
  constructor(props) {
    super(props);

    const reportDate = Partner.utilities.dates.report();


    this.state = {
      report: [],
      totalRebate: 0,
      formData: {
        id: Partner.utilities.publisher(),
        beginDate: reportDate.beginDate,
        endDate: reportDate.endDate,
        companyId: -1,
      },
    };

    this.onDateChange = this.onDateChange.bind(this);
    this.onFilter = this.onFilter.bind(this);
  }

  componentDidMount() {
    const { formData } = this.state;

    Service.Report.Rebate(formData).then(response => {
      if (response) {
        const totalRebate = response.map(item => item.totalRebate).reduce((a, b) => a + b, 0);

        this.setState({
          report: response,
          totalRebate,
        });
      }
    });
  }

  onFilter() {
    const { formData } = this.state;

    Service.Report.Rebate(formData).then(response => {
      if (response) {
        const totalRebate = response.map(item => item.totalRebate).reduce((a, b) => a + b, 0);

        this.setState({
          report: response,
          totalRebate,
        });
      }
    });
  }

  onDateChange(date) {
    const { formData } = this.state;

    if (date !== null && isArray(date)) {
      const [one, two] = date;
      formData.beginDate = one;
      formData.endDate = two;
      this.setState({
        formData,
      }, () => {
        localStorage.setItem('reportDate', JSON.stringify({ beginDate: one, endDate: two }));
      });
    }
  }

  render() {
    const { totalRebate, report, formData } = this.state;
    return (
      <>
        <BreadCrumb Title="Rebate Report" onlyAdmin Description="Please select company and date range to check your related rebate report." />
        <Card Title="Rebate Report" Description="Please select company and date range to check your related rebate report.">
          <div className="row mb-3">
            <div className="col-lg-12 pp-filter-wrapper">
              <div className="form-group mb-0">
                <label>{T('Company')}</label>
                <CompanySelect onChange={
                  option => this.setState(prevState => ({
                    formData: {
                      ...prevState.formData,
                      companyId: option.value,
                    },
                  }))
                }
                />
              </div>
              <div className="form-group mb-0">
                <label>{T('Date Range')}</label>
                <div>
                  <DateRangePicker
                    className="date-range-picker form-control"
                    onChange={this.onDateChange}
                    value={[formData.beginDate, formData.endDate]}
                    format="dd.MM.yyyy"
                  />
                </div>
              </div>
              <div className="form-group mb-0">
                <label>&nbsp;</label>
                <button type="button" className="btn btn-dark bg-dark btn-block" onClick={() => this.onFilter()}>{T('Filter Report')}</button>
              </div>
            </div>
          </div>
          <div className="table-responsive">
            <table className="table table-bordered mb-0">
              <tbody>
                <Collapse title={T('Total Rebate')} rebate={totalRebate}>
                  {
                    report.map(company => {
                      const ibTotal = company.iBrokerAccounts.map(item => item.totalRebate).reduce((a, b) => a + b, 0);
                      const partnerTotal = company.partnerAccounts.map(item => item.totalRebate).reduce((a, b) => a + b, 0);
                      const myTotal = company.myAccounts.map(item => item.totalRebate).reduce((a, b) => a + b, 0);

                      return (
                        <Collapse key={company.companyId} title={company.companyTitle} rebate={company.totalRebate} padClass="pl-4" logo={company.companyLogo}>
                          <Collapse title={T('My IBs')} rebate={ibTotal} padClass="pl-5" pullRight={`${company.iBrokerAccounts.length} ${T('IBs')}`}>
                            <UserHeader />
                            {company.iBrokerAccounts.length === 0 && <NoData />}
                            {company.iBrokerAccounts.map(user => (
                              <Collapse key={user.login} title={user.login} rebate={user.totalRebate} padClass="pl-5" titlePad="pl-3" colSpan="0" group={user.group}>
                                <SymbolHeader login={user.login} />
                                {user.rebates.map(rebate => <Symbol key={rebate.symbol} symbol={rebate.symbol} rebate={rebate.amount} />)}
                              </Collapse>
                            ))}
                          </Collapse>
                          <Collapse title={T('My Customers')} rebate={partnerTotal} padClass="pl-5" pullRight={`${company.partnerAccounts.length} ${T('Customers')}`}>
                            <UserHeader />
                            {company.partnerAccounts.length === 0 && <NoData />}
                            {company.partnerAccounts.map(user => (
                              <Collapse key={user.login} title={user.login} rebate={user.totalRebate} padClass="pl-5" titlePad="pl-3" colSpan="0" group={user.group}>
                                <SymbolHeader login={user.login} />
                                {user.rebates.map(rebate => <Symbol key={rebate.symbol} symbol={rebate.symbol} rebate={rebate.amount} />)}
                              </Collapse>
                            ))}
                          </Collapse>
                          <Collapse title={T('My Accounts')} rebate={myTotal} padClass="pl-5" pullRight={`${company.myAccounts.length} ${T('Customers')}`}>
                            <UserHeader />
                            {company.myAccounts.length === 0 && <NoData />}
                            {company.myAccounts.map(user => (
                              <Collapse key={user.login} title={user.login} rebate={user.totalRebate} padClass="pl-5" titlePad="pl-3" colSpan="0" group={user.group}>
                                <SymbolHeader login={user.login} />
                                {user.rebates.map(rebate => <Symbol key={rebate.symbol} symbol={rebate.symbol} rebate={rebate.amount} />)}
                              </Collapse>
                            ))}
                          </Collapse>
                        </Collapse>
                      );
                    })
                  }
                </Collapse>
              </tbody>
            </table>
          </div>
        </Card>
      </>
    );
  }
}
