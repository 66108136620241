import { Loading } from 'react-loading-ui';
import { Partner } from '../config/AppConfig';
import { Fetch } from '../components';


export const UserService = {
  /**
 * [LOGIN]
 * @param {object} data
 * @param {string} data.login
 * @param {string} data.password
 */
  Login(data) {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    };

    return Fetch(Partner.service.user.login, requestOptions);
  },
  /**
 * [CUSTOMERLIST]
 * @param {object} data
 * @param {int} data.id
* @param {int} data.companyId
 * @param {int} data.publisherId
 */
  CustomerList(data) {
    const requestOptions = {
      method: 'POST',
      headers: Partner.utilities.authorizedHeader(),
      body: JSON.stringify(data),
    };

    return Fetch(Partner.service.user.customerList, requestOptions);
  },

  /**
 * [TRADELIST]
 * @param {object} data
 * @param {int} data.login
 * @param {int} data.companyId
 * @param {string} data.beginDate
 * @param {string} data.endDate
 */
  TradeList(data) {
    const requestOptions = {
      method: 'POST',
      headers: Partner.utilities.authorizedHeader(),
      body: JSON.stringify(data),
    };

    return Fetch(Partner.service.user.tradeList, requestOptions);
  },

  /**
 * [LEADLIST]
 * @param {object} data
 * @param {int} data.id
 * @param {int} data.companyId
 * @param {int} data.publisherId
 */
  LeadList(data) {
    const requestOptions = {
      method: 'POST',
      headers: Partner.utilities.authorizedHeader(),
      body: JSON.stringify(data),
    };

    return Fetch(Partner.service.user.leadList, requestOptions);
  },

  /**
* [LEADLIST]
* @param {object} data
* @param {int} data.id
* @param {int} data.companyId
* @param {int} data.publisherId
*/
  GroupList(data) {
    const requestOptions = {
      method: 'POST',
      headers: Partner.utilities.authorizedHeader(),
      body: JSON.stringify(data),
    };

    return Fetch(Partner.service.user.groupList, requestOptions);
  },

  /**
    * [LOGOUT]
    */
  Logout() {
    localStorage.removeItem('user');
    window.location.href = '/login';
  },
  /**
   * [RECOVERPASSWORD]
   * @param {object} data
   * @param {string} data.email
   */
  RecoverPassword(data, isAdmin) {
    const requestOptions = {
      method: 'POST',
      headers: isAdmin ? Partner.utilities.authorizedHeader() : {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    };

    return Fetch(Partner.service.user.recoverPassword, requestOptions);
  },
  /**
   * [RESETPASSWORD]
   * @param {object} data
   * @param {string} data.email
   * @param {string} data.password
   * @param {string} data.confirmPassword
   */
  ResetPassword(data) {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    };

    return Fetch(Partner.service.user.resetPassword, requestOptions);
  },
  /**
    * [AUTHENTICATED]
    */
  Authenticated() {
    const requestOptions = {
      method: 'POST',
      headers: Partner.utilities.authorizedHeader(),
      body: JSON.stringify({ id: Partner.utilities.publisher() }),
    };

    Loading();

    return fetch(Partner.service.user.authenticated, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        return false;
      })
      .then(json => {
        Loading();
        return json;
      })
      .catch(() => {
        Loading();
        return false;
      });
  },
};
