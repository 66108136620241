import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { T } from 'react-translator-component';
import { Validation } from '../../../components';


const PartnerCommissionSymbolSecurity = props => {
  const { list, data, onChangeSelect, validation } = props;

  return (
    <>
      <div className="form-group">
        <input type="hidden" className="form-control" id="id" name="id" />
      </div>
      <div className="form-group row ml-1 mr-1">
        <label htmlFor="login" className="col-sm-4 col-form-label">{T('Company')}</label>
        <div className="col-sm-8">
          <Select
            value={data.companySelected}
            options={list.company}
            onChange={option => onChangeSelect(option, 'companySelected')}
            isMulti={false}
          />
          <Validation Value={data.companySelected} Check={validation.checkValid} />
        </div>
      </div>
      <div className="form-group row ml-1 mr-1">
        <label htmlFor="login" className="col-sm-4 col-form-label">{T('Publisher')}</label>
        <div className="col-sm-8">
          <Select
            value={data.userPublisherIdSelected}
            options={list.publisher.get(data.companyId)}
            onChange={option => onChangeSelect(option, 'userPublisherIdSelected')}
            isMulti={false}
          />
          <Validation Value={data.userPublisherIdSelected} Check={validation.checkValid} />
        </div>
      </div>
      <div className="form-group row ml-1 mr-1">
        <label htmlFor="login" className="col-sm-4 col-form-label">{T('Product Group')}</label>
        <div className="col-sm-8">
          <Select
            value={data.productGroupSelected}
            options={list.productGroup.get(data.companyId)}
            onChange={option => onChangeSelect(option, 'productGroupSelected')}
            isMulti={false}
          />
          <Validation Value={data.productGroupSelected} Check={validation.checkValid} />
        </div>
      </div>
      <div className="form-group row ml-1 mr-1">
        <label htmlFor="login" className="col-sm-4 col-form-label">{T('Security Group')}</label>
        <div className="col-sm-8">
          <Select
            value={data.securityGroupSelected}
            options={list.securityGroup.get(data.companyId)?.get(data.productGroupId)}
            onChange={option => onChangeSelect(option, 'securityGroupSelected')}
            isMulti={false}
          />
          <Validation Value={data.securityGroupSelected} Check={validation.checkValid} />
        </div>
      </div>
      <div className="form-group row ml-1 mr-1">
        <label htmlFor="login" className="col-sm-4 col-form-label">{T('Symbol Security')}</label>
        <div className="col-sm-8">
          <Select
            value={data.symbolSecuritySelected}
            options={list.symbolSecurity.get(data.companyId)?.get(data.securityGroupId)}
            onChange={option => onChangeSelect(option, 'symbolSecuritySelected')}
            isMulti={false}
          />
          <Validation Value={data.symbolSecuritySelected} Check={validation.checkValid} />
        </div>
      </div>
      <div className="form-group row ml-1 mr-1">
        <label htmlFor="login" className="col-sm-4 col-form-label">{T('User Group Type')}</label>
        <div className="col-sm-8">
          <Select
            value={data.userGroupTypeSelected}
            options={list.userGroupType.get(data.companyId)}
            onChange={option => onChangeSelect(option, 'userGroupTypeSelected')}
            isMulti={false}
          />
          <Validation Value={data.userGroupTypeSelected} Check={validation.checkValid} />
        </div>
      </div>
      <div className="form-group row ml-1 mr-1">
        <label htmlFor="login" className="col-sm-4 col-form-label">{T('Amount')}</label>
        <div className="col-sm-8">
          <input type="number" className="form-control" id="amount" name="amount" placeholder={T('Amount')} required />
        </div>
      </div>
      <div className="form-group row ml-1 mr-1">
        <label htmlFor="login" className="col-sm-4 col-form-label">{T('Status')}</label>
        <div className="col-sm-8">
          <Select
            value={data.statusSelected}
            options={list.status}
            onChange={option => onChangeSelect(option, 'statusSelected')}
            isMulti={false}
          />
          <Validation Value={data.statusSelected} Check={validation.checkValid} />
        </div>
      </div>
      <div className="form-group row ml-1 mr-1">
        <label htmlFor="login" className="col-sm-4 col-form-label">{T('Rebate Approval Status')}</label>
        <div className="col-sm-8">
          <Select
            value={data.rebateApprovalStatusSelected}
            options={list.rebateApprovalStatus}
            onChange={option => onChangeSelect(option, 'rebateApprovalStatusSelected')}
            isMulti={false}
          />
          <Validation Value={data.rebateApprovalStatusSelected} Check={validation.checkValid} />
        </div>
      </div>
    </>
  );
};
PartnerCommissionSymbolSecurity.propTypes = {
  list: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  onChangeSelect: PropTypes.func.isRequired,
  validation: PropTypes.object.isRequired,
};

export default PartnerCommissionSymbolSecurity;
