import { Partner } from '../config/AppConfig';
import { Fetch } from '../components';


export const ReportService = {
  /**
 * [Rebate]
 * @param {object} data
 * @param {number} data.id
 * @param {int} data.companyId
 * @param {string} data.beginDate
 * @param {string} data.endDate
 */
  Rebate(data) {
    const requestOptions = {
      method: 'POST',
      headers: Partner.utilities.authorizedHeader(),
      body: JSON.stringify(data),
    };

    return Fetch(Partner.service.report.rebate, requestOptions);
  },

  /**
 * [DASHBOARD]
 * @param {object} data
 * @param {number} data.id
 * @param {int} data.companyId
 * @param {int} data.publisherId
 * @param {string} data.beginDate
 * @param {string} data.endDate
 */
  Dashboard(data) {
    const requestOptions = {
      method: 'POST',
      headers: Partner.utilities.authorizedHeader(),
      body: JSON.stringify(data),
    };

    return Fetch(Partner.service.report.dashboard, requestOptions);
  },
};
