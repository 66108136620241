import React from 'react';
import { T } from 'react-translator-component';
import PropTypes from 'prop-types';


export const ErrorMessage = props => {
  const { Validation, className } = props;
  return (
    <div className={`col-md-12 ${(Validation.error ? '' : 'd-none')}`}>
      <div className={`alert alert-danger alert-dismissible fade show mb-3 mt-2 ${className}`} role="alert">
        <strong>
          <i className="fas fa-exclamation-triangle mr-1" />
          <span>{T('Error !')}</span>
        </strong>
        {T(Validation.message)}
      </div>
    </div>
  );
};

ErrorMessage.propTypes = {
  Validation: PropTypes.object.isRequired,
  className: PropTypes.string,
};

ErrorMessage.defaultProps = {
  className: '',
};
