import React from 'react';
import { T } from 'react-translator-component';
import { memoize } from 'react-data-table-component';

const countryList = require('../config/constant/Country');

export const DataTableColumns = {
  customers: memoize(onTrade => [
    {
      name: T('Login'),
      selector: 'accountNumber',
      maxWidth: '75px',
      sortable: true,
      cell: d => <b>{`#${d.accountNumber}`}</b>,
    },
    {
      name: T('Company'),
      selector: 'companyTitle',
      maxWidth: '125px',
      sortable: true,
    },
    {
      name: T('Name'),
      selector: 'name',
      maxWidth: '200px',
      sortable: true,
    },
    {
      name: T('Country'),
      selector: 'country',
      maxWidth: '150px',
      sortable: true,
    },
    {
      name: T('City'),
      selector: 'city',
      maxWidth: '150px',
      sortable: true,
    },
    {
      name: T('E-Mail'),
      selector: 'email',
      maxWidth: '250px',
      sortable: true,
    },
    {
      name: T('Lead Source'),
      selector: 'leadSource',
      maxWidth: '125px',
      sortable: true,
    },
    {
      name: T('Phone'),
      selector: 'phone',
      maxWidth: '175px',
      sortable: true,
    },
    {
      name: T('Registration Date'),
      selector: 'registrationDate',
      maxWidth: '150px',
      sortable: true,
    }, {
      name: T('Trades'),
      selector: 'trades',
      maxWidth: '150px',
      cell: d => (
        <span className="text-nowrap">
          <button type="button" className="btn btn-info btn-sm waves-effect waves-light mr-1" title={T('Trade')} onClick={e => onTrade(d.accountNumber, d.companyId, e)}>
            <i className="far fa-list-alt" />
            &nbsp;&nbsp;
            {T('Trade')}
          </button>
        </span>
      ),
    },
  ]),
  trade: memoize(() => [
    {
      name: T('Ticket'),
      selector: 'ticket',
      sortable: true,
      cell: d => <b>{`#${d.ticket}`}</b>,
    },
    {
      name: T('Symbol'),
      selector: 'symbol',
      sortable: true,
    },
    {
      name: T('Volume'),
      selector: 'volume',
      sortable: true,
    },
    {
      name: T('Open Time'),
      selector: 'openTime',
      sortable: true,
    },
    {
      name: T('Close Time'),
      selector: 'closeTime',
      sortable: true,
    },
    {
      name: T('Profit'),
      selector: 'profit',
      sortable: true,
    },
  ]),
  lead: memoize(() => [
    {
      name: T('Boss Id'),
      selector: 'bossId',
      sortable: true,
      cell: d => <b>{d.bossId}</b>,
    },
    {
      name: T('Company'),
      selector: 'companyName',
      sortable: true,
    },
    {
      name: T('Update Date'),
      selector: 'updateDate',
      sortable: true,
    },
    {
      name: T('Fullname'),
      selector: 'fullname',
      sortable: true,
    },
    {
      name: T('Agent'),
      selector: 'agentName',
      sortable: true,
    },
    {
      name: T('Lead Status'),
      selector: 'leadStatusName',
      sortable: true,
    },
    {
      name: T('Call Status'),
      selector: 'callStatusName',
      sortable: true,
    },
    {
      name: T('Source'),
      selector: 'sourceName',
      sortable: true,
    },
    {
      name: T('Ib Id'),
      selector: 'iBrokerId',
      sortable: true,
    },
    {
      name: T('Ip Country'),
      selector: 'ipCountry',
      sortable: true,
    },
    {
      name: T('Country'),
      selector: 'countryName',
      sortable: true,
    },
  ]),
  admin: {
    menuItem: memoize(onEdit => [
      {
        name: T('Title'),
        selector: 'title',
        sortable: true,
        cell: d => <b>{d.title}</b>,
      },
      {
        name: T('Path'),
        selector: 'path',
        sortable: true,
      },
      {
        name: T('Class'),
        selector: 'class',
      },
      {
        name: T('Component'),
        selector: 'component',
        sortable: true,
      },
      {
        name: T('Action'),
        right: true,
        cell: d => (
          <span className="text-nowrap">
            <button type="button" className="btn btn-dark btn-sm waves-effect waves-light" title={T('Edit')} onClick={e => onEdit(d.id, e)}>
              <i className="far fa-edit" />
              &nbsp;
              {T('Edit')}
            </button>
          </span>

        ),
      },
    ]),
    symbol: memoize(onEdit => [
      {
        name: T('Id'),
        selector: 'id',
        sortable: true,
        cell: d => <b>{d.id}</b>,
      },
      {
        name: T('Company'),
        selector: 'companyName',
        sortable: true,
      },
      {
        name: T('Symbol Security'),
        selector: 'symbolSecurityName',
        sortable: true,
      },
      {
        name: T('symbol1'),
        selector: 'symbol1',
        sortable: true,
      },
      {
        name: T('Contract Size'),
        selector: 'contractSize',
        sortable: true,
      },
      {
        name: T('Currency'),
        selector: 'currency',
        sortable: true,
      },
      {
        name: T('Margin Currency'),
        selector: 'marginCurrency',
        sortable: true,
      },
      {
        name: T('Base Currency'),
        selector: 'baseCurrency',
        sortable: true,
      },
      {
        name: T('Base Multiplier'),
        selector: 'baseMultiplier',
        sortable: true,
      },
      {
        name: T('Symbol Convertion Type'),
        selector: 'symbolConvertionName',
        sortable: true,
      },
      {
        name: T('Multiplier'),
        selector: 'multiplier',
        sortable: true,
      },
      {
        name: T('Divider'),
        selector: 'divider',
        sortable: true,
      },
      {
        name: T('Action'),
        right: true,
        cell: d => (
          <span className="text-nowrap">
            <button type="button" className="btn btn-dark btn-sm waves-effect waves-light" title={T('Edit')} onClick={e => onEdit(d.id, e)}>
              <i className="far fa-edit" />
              &nbsp;
              {T('Edit')}
            </button>
          </span>

        ),
      },
    ]),
    user: memoize((onEdit, onDetail) => [
      {
        name: T('Id'),
        selector: 'id',
        maxWidth: '50px',
        sortable: true,
        cell: d => <b>{d.id}</b>,
      },
      {
        name: T('Email'),
        selector: 'email',
        sortable: true,
        cell: d => <span className="text-ellipsis">{d.email}</span>,
      },
      {
        name: T('Name'),
        selector: 'firstName',
        sortable: true,
        cell: d => `${d.firstName} ${d.lastName}`,
      },
      {
        name: T('Publisher'),
        selector: 'publisherList',
        sortable: true,
        cell: d => (
          <div className="normal-wrap">
            {d.publisherList.map(a => <span className={`badge badge-light mr-1 bg-${a.company.replace(' ', '').toLowerCase()}`} title={a.company}>{a.publisher}&nbsp;</span>)}
          </div>
        ),
      },
      {
        name: T('Role'),
        selector: 'roleName',
        sortable: true,
        width: '80px',
      },
      {
        name: T('Country'),
        selector: 'countryCode',
        width: '100px',
        sortable: true,
        cell: d => {
          const country = countryList.filter(f => f.value === d.countryCode)[0];
          return country && country.label;
        },
      },
      {
        name: T('Status'),
        selector: 'status',
        width: '75px',
        sortable: true,
        cell: d => {
          if (d.status) {
            return (
              <span className="badge pb-1 font-12 payment-status badge-success">
                {T('Active')}
              </span>
            );
          }
          return (
            <span className="badge pb-1 font-12 payment-status badge-danger">
              {T('Passive')}
            </span>
          );
        },
      },
      {
        name: T('Action'),
        right: true,
        cell: d => (
          <span className="text-nowrap">
            <button type="button" className="btn btn-primary btn-sm waves-effect waves-light mr-2" title={T('Detail')} onClick={e => onDetail(d.id, e)}>
              <i className="fas fa-user-cog" />
              &nbsp;
              {T('Detail')}
            </button>
            <button type="button" className="btn btn-dark btn-sm waves-effect waves-light" title={T('Edit')} onClick={e => onEdit(d.id, e)}>
              <i className="far fa-edit" />
              &nbsp;
              {T('Edit')}
            </button>

          </span>

        ),
      },
    ]),
    userGroupType: memoize(onEdit => [
      {
        name: T('Id'),
        selector: 'id',
        maxWidth: '50px',
        sortable: true,
        cell: d => <b>{d.id}</b>,
      },
      {
        name: T('Company'),
        selector: 'companyName',
        sortable: true,
      },
      {
        name: T('GroupType'),
        selector: 'groupType',
        sortable: true,
      },
      {
        name: T('User Group'),
        selector: 'userGroupList',
        sortable: true,
        cell: d => (
          <div className="normal-wrap">
            {d.userGroupList.map(a => <span className="badge badge-light mr-1">{a}</span>)}
          </div>
        ),
      },
      {
        name: T('Status'),
        selector: 'status',
        width: '75px',
        sortable: true,
        cell: d => {
          if (d.status) {
            return (
              <span className="badge pb-1 font-12 payment-status badge-success">
                {T('Active')}
              </span>
            );
          }
          return (
            <span className="badge pb-1 font-12 payment-status badge-danger">
              {T('Passive')}
            </span>
          );
        },
      },
      {
        name: T('Action'),
        right: true,
        cell: d => (
          <span className="text-nowrap">
            <button type="button" className="btn btn-dark btn-sm waves-effect waves-light" title={T('Edit')} onClick={e => onEdit(d.id, e)}>
              <i className="far fa-edit" />
              &nbsp;
              {T('Edit')}
            </button>
          </span>

        ),
      },
    ]),
    userGroup: memoize((onEdit, onEditSpread) => [
      {
        name: T('Id'),
        selector: 'id',
        maxWidth: '50px',
        sortable: true,
        cell: d => <b>{d.id}</b>,
      },
      {
        name: T('Company'),
        selector: 'companyName',
        sortable: true,
      },
      {
        name: T('UserGroupType'),
        selector: 'groupTypeName',
        sortable: true,
      },
      {
        name: T('Group'),
        selector: 'group',
        sortable: true,
      },
      {
        name: T('Status'),
        selector: 'status',
        width: '75px',
        sortable: true,
        cell: d => {
          if (d.status) {
            return (
              <span className="badge pb-1 font-12 payment-status badge-success">
                {T('Active')}
              </span>
            );
          }
          return (
            <span className="badge pb-1 font-12 payment-status badge-danger">
              {T('Passive')}
            </span>
          );
        },
      },
      {
        name: T('Action'),
        right: true,
        cell: d => (
          <>
            <span className="text-nowrap">
              <button type="button" className="btn btn-primary btn-sm waves-effect waves-light mr-2" title={T('Edit Spread')} onClick={e => onEditSpread(d.id, d.group, e)}>
                <i className="fas fa-th" />
                &nbsp;
                {T('Edit Spread')}
              </button>
              <button type="button" className="btn btn-dark btn-sm waves-effect waves-light" title={T('Edit')} onClick={e => onEdit(d.id, e)}>
                <i className="far fa-edit" />
                &nbsp;
                {T('Edit')}
              </button>
            </span>
          </>
        ),
      },
    ]),
    symbolConvertionType: memoize(onEdit => [
      {
        name: T('Id'),
        selector: 'id',
        maxWidth: '50px',
        sortable: true,
        cell: d => <b>{d.id}</b>,
      },
      {
        name: T('Company'),
        selector: 'companyName',
        sortable: true,
      },
      {
        name: T('Type'),
        selector: 'type',
        sortable: true,
      },
      {
        name: T('Action'),
        right: true,
        cell: d => (
          <span className="text-nowrap">
            <button type="button" className="btn btn-dark btn-sm waves-effect waves-light" title={T('Edit')} onClick={e => onEdit(d.id, e)}>
              <i className="far fa-edit" />
              &nbsp;
              {T('Edit')}
            </button>
          </span>

        ),
      },
    ]),
    securityGroup: memoize(onEdit => [
      {
        name: T('Id'),
        selector: 'id',
        maxWidth: '50px',
        sortable: true,
        cell: d => <b>{d.id}</b>,
      },
      {
        name: T('Company'),
        selector: 'companyName',
        sortable: true,
      },
      {
        name: T('Product Group'),
        selector: 'productGroupName',
        sortable: true,
      },
      {
        name: T('Group Name'),
        selector: 'groupName',
        sortable: true,
      },
      {
        name: T('Comment'),
        selector: 'comment',
        sortable: true,
      },
      {
        name: T('Row Number'),
        selector: 'rowNumber',
        sortable: true,
      },
      {
        name: T('Action'),
        right: true,
        cell: d => (
          <span className="text-nowrap">
            <button type="button" className="btn btn-dark btn-sm waves-effect waves-light" title={T('Edit')} onClick={e => onEdit(d.id, e)}>
              <i className="far fa-edit" />
              &nbsp;
              {T('Edit')}
            </button>
          </span>

        ),
      },
    ]),
    symbolSecurity: memoize(onEdit => [
      {
        name: T('Id'),
        selector: 'id',
        sortable: true,
        cell: d => <b>{d.id}</b>,
      },
      {
        name: T('Company'),
        selector: 'companyName',
        sortable: true,
      },
      {
        name: T('Security Group'),
        selector: 'securityGroupName',
        sortable: true,
      },
      {
        name: T('Security'),
        selector: 'security',
        sortable: true,
      },
      {
        name: T('Description'),
        selector: 'description',
        sortable: true,
      },
      {
        name: T('Divider'),
        selector: 'divider',
        sortable: true,
      },
      {
        name: T('Row Number'),
        selector: 'rowNumber',
        sortable: true,
      },
      {
        name: T('Action'),
        right: true,
        cell: d => (
          <span className="text-nowrap">
            <button type="button" className="btn btn-dark btn-sm waves-effect waves-light" title={T('Edit')} onClick={e => onEdit(d.id, e)}>
              <i className="far fa-edit" />
              &nbsp;
              {T('Edit')}
            </button>
          </span>

        ),
      },
    ]),
    productGroup: memoize(onEdit => [
      {
        name: T('Id'),
        selector: 'id',
        sortable: true,
        cell: d => <b>{d.id}</b>,
      },
      {
        name: T('Company'),
        selector: 'companyName',
        sortable: true,
      },
      {
        name: T('Product Group'),
        selector: 'productGroup1',
        sortable: true,
      },
      {
        name: T('Row Number'),
        selector: 'rowNumber',
        sortable: true,
      },
      {
        name: T('Action'),
        right: true,
        cell: d => (
          <span className="text-nowrap">
            <button type="button" className="btn btn-dark btn-sm waves-effect waves-light" title={T('Edit')} onClick={e => onEdit(d.id, e)}>
              <i className="far fa-edit" />
              &nbsp;
              {T('Edit')}
            </button>
          </span>

        ),
      },
    ]),
    menuItemRole: memoize(onEdit => [
      {
        name: T('Id'),
        selector: 'id',
        sortable: true,
        cell: d => <b>{d.id}</b>,
      },
      {
        name: T('Menu Item'),
        selector: 'menuItemName',
        sortable: true,
      },
      {
        name: T('Role'),
        selector: 'roleName',
        sortable: true,
      },
      {
        name: T('Action'),
        right: true,
        cell: d => (
          <span className="text-nowrap">
            <button type="button" className="btn btn-dark btn-sm waves-effect waves-light" title={T('Edit')} onClick={e => onEdit(d.id, e)}>
              <i className="far fa-edit" />
              &nbsp;
              {T('Edit')}
            </button>
          </span>

        ),
      },
    ]),
    role: memoize(onEdit => [
      {
        name: T('Id'),
        selector: 'id',
        sortable: true,
        cell: d => <b>{d.id}</b>,
      },
      {
        name: T('Title'),
        selector: 'title',
        sortable: true,
      },
      {
        name: T('Description'),
        selector: 'description',
        sortable: true,
      },
      {
        name: T('Common'),
        selector: 'common',
        sortable: true,
      },
      {
        name: T('Action'),
        right: true,
        cell: d => (
          <span className="text-nowrap">
            <button type="button" className="btn btn-dark btn-sm waves-effect waves-light" title={T('Edit')} onClick={e => onEdit(d.id, e)}>
              <i className="far fa-edit" />
              &nbsp;
              {T('Edit')}
            </button>
          </span>

        ),
      },
    ]),
    company: memoize(onEdit => [
      {
        name: T('Id'),
        selector: 'id',
        sortable: true,
        cell: d => <b>{d.id}</b>,
      },
      {
        name: T('Title'),
        selector: 'title',
        sortable: true,
      },
      {
        name: T('Logo'),
        selector: 'logo',
        cell: d => (
          <>
            <img src={`/assets/company/${d.logo || 'defaultlogo.svg'}`} alt="" width="30" className="mb-1" />
            <span>
              {d.logo}
            </span>
          </>
        ),
      },
      {
        name: T('Common'),
        selector: 'common',
      },
      {
        name: T('Action'),
        right: true,
        cell: d => (
          <span className="text-nowrap">
            <button type="button" className="btn btn-dark btn-sm waves-effect waves-light" title={T('Edit')} onClick={e => onEdit(d.id, e)}>
              <i className="far fa-edit" />
              &nbsp;
              {T('Edit')}
            </button>
          </span>

        ),
      },
    ]),
    userBossAccount: memoize(onEdit => [
      {
        name: T('Id'),
        sortable: true,
        selector: 'id',
        cell: d => <b>{d.id}</b>,
      },
      {
        name: T('Company'),
        selector: 'companyName',
        sortable: true,
      },
      {
        name: T('User'),
        selector: 'userName',
        sortable: true,
      },
      {
        name: T('Boss Id'),
        selector: 'bossId',
        sortable: true,
      },
      {
        name: T('Action'),
        right: true,
        cell: d => (
          <span className="text-nowrap">
            <button type="button" className="btn btn-dark btn-sm waves-effect waves-light" title={T('Edit')} onClick={e => onEdit(d.id, e)}>
              <i className="far fa-edit" />
              &nbsp;
              {T('Edit')}
            </button>
          </span>

        ),
      },
    ]),
    userPublisherAccount: memoize(onEdit => [
      {
        name: T('Id'),
        selector: 'id',
        sortable: true,
        cell: d => <b>{d.id}</b>,
      },
      {
        name: T('Company'),
        selector: 'companyName',
        sortable: true,
      },
      {
        name: T('User'),
        selector: 'userName',
        sortable: true,
      },
      {
        name: T('Publisher'),
        selector: 'publisherId',
        sortable: true,
      },
      {
        name: T('Parent Publisher'),
        selector: 'parentPublisher',
        sortable: true,
        cell: d => `${d.parentUserName === null ? '' : `${d.parentUserName} - `} ${d.parentPublisher}`,
      },
      {
        name: T('Meta Account'),
        selector: 'metaAccount',
        sortable: true,
      }, {
        name: T('Default Group'),
        selector: 'defaultGroup',
        sortable: true,
      },
      {
        name: T('Status'),
        selector: 'status',
        width: '75px',
        sortable: true,
        cell: d => {
          if (d.status) {
            return (
              <span className="badge pb-1 font-12 payment-status badge-success">
                {T('Active')}
              </span>
            );
          }
          return (
            <span className="badge pb-1 font-12 payment-status badge-danger">
              {T('Passive')}
            </span>
          );
        },
      },
      {
        name: T('Action'),
        right: true,
        cell: d => (
          <span className="text-nowrap">
            <button type="button" className="btn btn-dark btn-sm waves-effect waves-light" title={T('Edit')} onClick={e => onEdit(d.id, e)}>
              <i className="far fa-edit" />
              &nbsp;
              {T('Edit')}
            </button>
          </span>

        ),
      },
    ]),
    rebatePartner: memoize(onEdit => [
      {
        name: T('Id'),
        selector: 'id',
        sortable: true,
      },
      {
        name: T('Company'),
        selector: 'companyName',
        sortable: true,
      },
      {
        name: T('Publisher Name'),
        selector: 'publisherName',
        sortable: true,
      },
      {
        name: T('Publisher'),
        selector: 'userPublisherId',
        sortable: true,
      },
      {
        name: T('Product Group'),
        selector: 'productGroupName',
        sortable: true,
      },
      {
        name: T('Security Group'),
        selector: 'securityGroupName',
        sortable: true,
      }, {
        name: T('Symbol Security'),
        selector: 'symbolSecurityName',
        sortable: true,
      },
      {
        name: T('User Group Type'),
        selector: 'userGroupTypeName',
        sortable: true,
      },
      {
        name: T('Amount'),
        selector: 'amount',
        sortable: true,
      },
      {
        name: T('Status'),
        selector: 'status',
        width: '75px',
        sortable: true,
        cell: d => {
          if (d.status) {
            return (
              <span className="badge pb-1 font-12 payment-status badge-success">
                {T('Active')}
              </span>
            );
          }
          return (
            <span className="badge pb-1 font-12 payment-status badge-danger">
              {T('Passive')}
            </span>
          );
        },
      },
      {
        name: T('Type'),
        selector: 'rebateType',
        sortable: true,
      },
      {
        name: T('Action'),
        right: true,
        cell: d => (
          <span className="text-nowrap">
            <button type="button" className="btn btn-dark btn-sm waves-effect waves-light" title={T('Edit')} onClick={e => onEdit(d.id, d.rebateType, e)}>
              <i className="far fa-edit" />
              &nbsp;
              {T('Edit')}
            </button>
          </span>

        ),
      },
    ]),
    rebateIBroker: memoize(onEdit => [
      {
        name: T('Id'),
        selector: 'id',
        sortable: true,
      },
      {
        name: T('Company'),
        selector: 'companyName',
        sortable: true,
      },
      {
        name: T('Master Name'),
        selector: 'masterIBrokerName',
        sortable: true,
      },
      {
        name: T('Master IBroker'),
        selector: 'masterIb',
        sortable: true,
      },
      {
        name: T('Sub Name'),
        selector: 'subIBrokerName',
        sortable: true,
      },
      {
        name: T('Sub IBroker'),
        selector: 'subIb',
        sortable: true,
      },
      {
        name: T('Product Group'),
        selector: 'productGroupName',
        sortable: true,
      },
      {
        name: T('Security Group'),
        selector: 'securityGroupName',
        sortable: true,
      }, {
        name: T('Symbol Security'),
        selector: 'symbolSecurityName',
        sortable: true,
      },
      {
        name: T('User Group Type'),
        selector: 'userGroupTypeName',
        sortable: true,
      },
      {
        name: T('Amount'),
        selector: 'amount',
        sortable: true,
      },
      {
        name: T('Status'),
        selector: 'status',
        width: '75px',
        sortable: true,
        cell: d => {
          if (d.status) {
            return (
              <span className="badge pb-1 font-12 payment-status badge-success">
                {T('Active')}
              </span>
            );
          }
          return (
            <span className="badge pb-1 font-12 payment-status badge-danger">
              {T('Passive')}
            </span>
          );
        },
      },
      {
        name: T('Type'),
        selector: 'rebateType',
        sortable: true,
      },
      {
        name: T('Action'),
        right: true,
        cell: d => (
          <span className="text-nowrap">
            <button type="button" className="btn btn-dark btn-sm waves-effect waves-light" title={T('Edit')} onClick={e => onEdit(d.id, d.rebateType, e)}>
              <i className="far fa-edit" />
              &nbsp;
              {T('Edit')}
            </button>
          </span>

        ),
      },
    ]),
    rebateTrade: [
      {
        name: T('Ticket'),
        selector: 'id',
        sortable: true,
        cell: d => <b>{d.id}</b>,
      },
      {
        name: T('Company'),
        selector: 'companyName',
        sortable: true,
      },
      {
        name: T('Login'),
        selector: 'login',
        sortable: true,
      },
      {
        name: T('Group'),
        selector: 'group',
        width: '200px',
        sortable: true,
      },
      {
        name: T('Symbol'),
        selector: 'symbol',
        sortable: true,
      },
      {
        name: T('Cmd'),
        selector: 'cmd',
        sortable: true,
        cell: d => (d.cmd === 0 ? 'Buy' : 'Sell'),
      },
      {
        name: T('Volume'),
        selector: 'volume',
        sortable: true,
        cell: d => (d.volume / 100).toFixed(2),
      },
      {
        name: T('Open Time'),
        selector: 'openTime',
        width: '150px',
        sortable: true,
      },
      {
        name: T('Close Time'),
        selector: 'closeTime',
        width: '150px',
        sortable: true,
      },
      {
        name: T('Publisher'),
        selector: 'publisherName',
        width: '250px',
        sortable: true,
      },
      {
        name: T('Spread'),
        selector: 'spread',
        sortable: true,
      },
      {
        name: T('Calculated Spread'),
        selector: 'calculatedSpread',
        sortable: true,
      },
      {
        name: T('Calculated Volume'),
        selector: 'calculatedVolume',
        sortable: true,
      },
      {
        name: T('Calculated Sum Volume'),
        selector: 'calculatedSumVolume',
        sortable: true,
      },
      {
        name: T('Amount'),
        selector: 'amount',
        sortable: true,
      },
      {
        name: T('Calculated Amount'),
        selector: 'calculatedAmount',
        sortable: true,
      },
      {
        name: T('Calculated Usd Amount'),
        selector: 'calculatedUsdAmount',
        sortable: true,
      },
      {
        name: T('Type'),
        selector: 'type',
        width: '200px',
        sortable: true,
      },
    ],
  },
};
