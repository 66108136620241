import React from 'react';
import { T } from 'react-translator-component';

const Footer = () => (
  <footer className="footer">
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          {`© ${new Date().getFullYear()} ${T('TRA Information Technologies')}`}
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
