import PartnerCommission from './PartnerCommission';
import PartnerCommissionSymbolSecurity from './PartnerCommissionSymbolSecurity';
import PartnerLotVolume from './PartnerLotVolume';
import PartnerSymbol from './PartnerSymbol';
import PartnerSymbolSecurity from './PartnerSymbolSecurity';
import IBrokerCommission from './IBrokerCommission';
import IBrokerCommissionSymbolSecurity from './IBrokerCommissionSymbolSecurity';
import IBrokerLotVolume from './IBrokerLotVolume';
import IBrokerSymbol from './IBrokerSymbol';
import IBrokerSymbolSecurity from './IBrokerSymbolSecurity';

export const RebateTemplate = {
  PartnerCommission,
  PartnerCommissionSymbolSecurity,
  PartnerLotVolume,
  PartnerSymbol,
  PartnerSymbolSecurity,
  IBrokerCommission,
  IBrokerCommissionSymbolSecurity,
  IBrokerLotVolume,
  IBrokerSymbol,
  IBrokerSymbolSecurity,
};
