import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { T } from 'react-translator-component';
import { Partner } from '../config/AppConfig';

export const BreadCrumb = props => {
  const { Title, Description, Name, SubTitle, SubUrl, onlyAdmin } = props;

  const publisherList = [{ value: -1, label: 'Admin' }].concat(Partner.utilities.user().publisherList);

  const [selectedPublisher, setSelectedPublisher] = useState(publisherList.filter(item => item.value === Partner.utilities.publisher()));

  const onChangePublisher = option => {
    localStorage.setItem('publisher', option.value);
    setSelectedPublisher(option);
    document.location.reload();
  };

  return (
    <>
      {
        ((onlyAdmin && Partner.utilities.user().roleName !== 'Partner') || !onlyAdmin) && (
          <div className="page-title-box">
            <div className="row align-items-center">
              <div className="col-sm-10">
                <h4 className="page-title">{T(Title)}</h4>
                <ol className="breadcrumb">
                  {Description && <li className="breadcrumb-item">{T(Description)}</li>}
                  {Name && (
                    <>
                      <li className="breadcrumb-item"><Link to="/home">{T('Home')}</Link></li>
                      {SubTitle && <li className="breadcrumb-item"><Link to={SubUrl}>{`(${SubTitle})`}</Link></li>}
                      <li className="breadcrumb-item active">{T(Name)}</li>
                    </>
                  )}
                </ol>
              </div>
              {/* {alert()} */}
              {Partner.utilities.user().roleName !== 'Partner' &&
                (
                  <div className="col-md-2">
                    <div className="form-group mb-0 text-right">
                      <label className="text-dark">{T('Publisher')}</label>
                      <Select
                        id="publisher"
                        value={selectedPublisher}
                        onChange={option => onChangePublisher(option)}
                        options={publisherList}
                        isMulti={false}
                        name="publisher"
                        className="text-left"
                      />
                    </div>
                  </div>
                )
              }
            </div>
          </div>
        )}
    </>
  );
};

BreadCrumb.propTypes = {
  Title: PropTypes.string,
  Description: PropTypes.string,
  Name: PropTypes.array,
  SubTitle: PropTypes.string,
  SubUrl: PropTypes.string,
  onlyAdmin: PropTypes.bool,
};

BreadCrumb.defaultProps = {
  Title: null,
  Description: null,
  Name: null,
  SubTitle: null,
  SubUrl: '',
  onlyAdmin: false,
};
