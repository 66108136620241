import React from 'react';
import PropTypes from 'prop-types';
import { T } from 'react-translator-component';

export const Modal = props => {
  const { Show, children, Title, onClose, isScrollable } = props;
  return (
    (Show) ? (
      <>
        <div className="modal fade bs-example-modal-lg show d-block fadeIn" tabIndex="-1" role="dialog">
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title mt-0" id="myLargeModalLabel">{Title}</h5>
                <button type="button" className="close" data-dismiss="modal" onClick={onClose} aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className={`modal-body ${isScrollable === true ? 'modal-body-scrollable' : ''}`}>
                {children}
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary waves-effect" onClick={onClose} data-dismiss="modal">{T('Close')}</button>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-backdrop fade show" />
      </>
    )
      : ''
  );
};
Modal.propTypes = {
  Show: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  Title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  isScrollable: PropTypes.bool,
};

Modal.defaultProps = {
  isScrollable: false,
};
