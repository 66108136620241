  export const Partner = {
  config: {
    /**
   * [GENERAL]
   */
    // eslint-disable-next-line func-names, prefer-arrow-callback, no-unused-vars
    debug: /param/.test(function (param) { }),
  },
  api: {
    release: {
      user: 'https://service.partnerpanel.online/api',
      rebate: 'https://service.partnerpanel.online/api',
    },
    debug: {
      user: 'https://localhost:44356/api',
      rebate: 'https://localhost:44396/api',
    },
    user: url => (Partner.config.debug ? Partner.api.debug.user.concat(url) : Partner.api.release.user.concat(url)),
    rebate: url => (Partner.config.debug ? Partner.api.debug.rebate.concat(url) : Partner.api.release.rebate.concat(url)),
  },
  service: {
    user: {},
    report: {},
    admin: {},
  },
  utilities: {
    authorizedHeader: () => {
      const user = JSON.parse(localStorage.getItem('user'));
      if (user && user.token) {
        return { 'Content-Type': 'application/json', Authorization: `Bearer ${user.token}` };
      }
      return { 'Content-Type': 'application/json' };
    },
    user: () => (JSON.parse(localStorage.getItem('user'))),
    userToken: () => (Partner.config.utilities.user.token),
    // eslint-disable-next-line radix
    publisher: () => (localStorage.getItem('publisher') ? parseInt(localStorage.getItem('publisher')) : -1),
    companyList: () => (
      Partner.utilities.user()
        .publisherAccount.filter((obj, pos, arr) => arr.map(mapObj => mapObj.companyId).indexOf(obj.companyId) === pos)
        .map(item => ({ companyId: item.companyId, companyName: item.companyName, logo: item.logo }))
    ),
    dates: {
      report: () => {
        const reportDate = localStorage.getItem('reportDate');
        const date = new Date();
        date.setDate(date.getDate() - 1);

        return (JSON.parse(reportDate) || { beginDate: date, endDate: date });
      },
      dashboard: () => {
        const dashboardDate = localStorage.getItem('dashboardDate');
        const date = new Date();

        return (JSON.parse(dashboardDate) || { beginDate: new Date(date.getFullYear(), date.getMonth(), 1), endDate: date });
      },
    },
  },
};

Partner.service.user = {
  login: Partner.api.user('/user/login'),
  authenticated: Partner.api.user('/user/authenticated'),
  customerList: Partner.api.user('/user/customerlist'),
  tradeList: Partner.api.user('/user/tradeList'),
  leadList: Partner.api.user('/user/leadList'),
  groupList: Partner.api.user('/user/groupList'),
  recoverPassword: Partner.api.user('/user/recoverpassword'),
  resetPassword: Partner.api.user('/user/resetPassword'),
};

Partner.service.report = {
  rebate: Partner.api.user('/report/rebate'),
  dashboard: Partner.api.user('/report/dashboard'),
};

Partner.service.admin = {
  menuItem: {
    list: Partner.api.user('/admin/menuitem/list'),
    save: Partner.api.user('/admin/menuitem/save'),
    detail: Partner.api.user('/admin/menuitem/get'),
  },
  symbol: {
    list: Partner.api.user('/admin/symbol/list'),
    save: Partner.api.user('/admin/symbol/save'),
    detail: Partner.api.user('/admin/symbol/get'),
  },
  user: {
    list: Partner.api.user('/admin/user/list'),
    save: Partner.api.user('/admin/user/save'),
    detail: Partner.api.user('/admin/user/get'),
  },
  userGroupType: {
    list: Partner.api.user('/admin/usergrouptype/list'),
    save: Partner.api.user('/admin/usergrouptype/save'),
    detail: Partner.api.user('/admin/usergrouptype/get'),
  },
  userGroup: {
    list: Partner.api.user('/admin/usergroup/list'),
    save: Partner.api.user('/admin/usergroup/save'),
    detail: Partner.api.user('/admin/usergroup/get'),
  },
  symbolConvertionType: {
    list: Partner.api.user('/admin/symbolconvertiontype/list'),
    save: Partner.api.user('/admin/symbolconvertiontype/save'),
    detail: Partner.api.user('/admin/symbolconvertiontype/get'),
  },
  securityGroup: {
    list: Partner.api.user('/admin/securitygroup/list'),
    save: Partner.api.user('/admin/securitygroup/save'),
    detail: Partner.api.user('/admin/securitygroup/get'),
  },
  symbolSecurity: {
    list: Partner.api.user('/admin/symbolsecurity/list'),
    save: Partner.api.user('/admin/symbolsecurity/save'),
    detail: Partner.api.user('/admin/symbolsecurity/get'),
  },
  productGroup: {
    list: Partner.api.user('/admin/productgroup/list'),
    save: Partner.api.user('/admin/productgroup/save'),
    detail: Partner.api.user('/admin/productgroup/get'),
  },
  menuItemRole: {
    list: Partner.api.user('/admin/menuitemrole/list'),
    save: Partner.api.user('/admin/menuitemrole/save'),
    detail: Partner.api.user('/admin/menuitemrole/get'),
  },
  role: {
    list: Partner.api.user('/admin/role/list'),
    save: Partner.api.user('/admin/role/save'),
    detail: Partner.api.user('/admin/role/get'),
  },
  company: {
    list: Partner.api.user('/admin/company/list'),
    save: Partner.api.user('/admin/company/save'),
    detail: Partner.api.user('/admin/company/get'),
  },
  userBossAccount: {
    list: Partner.api.user('/admin/userbossaccount/list'),
    save: Partner.api.user('/admin/userbossaccount/save'),
    detail: Partner.api.user('/admin/userbossaccount/get'),
  },
  userPublisherAccount: {
    list: Partner.api.user('/admin/userpublisheraccount/list'),
    save: Partner.api.user('/admin/userpublisheraccount/save'),
    detail: Partner.api.user('/admin/userpublisheraccount/get'),
  },
  rebate: {
    list: Partner.api.user('/admin/rebate/list'),
    filter: Partner.api.user('/admin/rebate/filter'),
    trade: Partner.api.user('/admin/rebate/trade'),
    manual: Partner.api.user('/rebate/manual'),
    report: Partner.api.user('/rebate/report'),
    partnercommission: {
      save: Partner.api.user('/admin/rebate/partnercommission/save'),
      detail: Partner.api.user('/admin/rebate/partnercommission/get'),
    },
    partnercommissionsymbolsecurity: {
      save: Partner.api.user('/admin/rebate/partnercommissionsymbolsecurity/save'),
      detail: Partner.api.user('/admin/rebate/partnercommissionsymbolsecurity/get'),
    },
    partnerlotvolume: {
      save: Partner.api.user('/admin/rebate/partnerlotvolume/save'),
      detail: Partner.api.user('/admin/rebate/partnerlotvolume/get'),
    },
    partnersymbol: {
      save: Partner.api.user('/admin/rebate/partnersymbol/save'),
      detail: Partner.api.user('/admin/rebate/partnersymbol/get'),
    },
    partnersymbolsecurity: {
      save: Partner.api.user('/admin/rebate/partnersymbolsecurity/save'),
      detail: Partner.api.user('/admin/rebate/partnersymbolsecurity/get'),
    },
    ibrokercommission: {
      save: Partner.api.user('/admin/rebate/ibrokercommission/save'),
      detail: Partner.api.user('/admin/rebate/ibrokercommission/get'),
    },
    ibrokercommissionsymbolsecurity: {
      save: Partner.api.user('/admin/rebate/ibrokercommissionsymbolsecurity/save'),
      detail: Partner.api.user('/admin/rebate/ibrokercommissionsymbolsecurity/get'),
    },
    ibrokerlotvolume: {
      save: Partner.api.user('/admin/rebate/ibrokerlotvolume/save'),
      detail: Partner.api.user('/admin/rebate/ibrokerlotvolume/get'),
    },
    ibrokersymbol: {
      save: Partner.api.user('/admin/rebate/ibrokersymbol/save'),
      detail: Partner.api.user('/admin/rebate/ibrokersymbol/get'),
    },
    ibrokersymbolsecurity: {
      save: Partner.api.user('/admin/rebate/ibrokersymbolsecurity/save'),
      detail: Partner.api.user('/admin/rebate/ibrokersymbolsecurity/get'),
    },
  },
  spread: {
    detail: Partner.api.user('/admin/spread/get'),
    save: Partner.api.user('/admin/spread/save'),
  },
};
