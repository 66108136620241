import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { T } from 'react-translator-component';
import PropTypes from 'prop-types';
import { Service } from '../../services';
import { ErrorMessage } from '../../components/ErrorMessage';

export class ResetPassword extends Component {
  constructor(props) {
    super(props);
    const { match: { params } } = props;
    const { token } = params;

    this.state = {
      formData: {
        email: '',
        password: null,
        confirmPassword: null,
        token,
      },
      validation: {
        error: false,
        message: 'Please enter your email!',
      },
      disabled: false,
    };
  }

  onChange(key, value) {
    const { formData } = this.state;
    formData[key] = value;
    this.setState({
      formData,
    });
  }

  onReset() {
    const { formData } = this.state;
    const { validation } = this.state;

    validation.error = false;
    if (formData.email === null || formData.email === '') {
      validation.error = true;
      validation.message = 'Please enter your email!';
    }
    if (formData.password.length < 6) {
      validation.error = true;
      validation.message = 'Password length must be at least 6 characters';
    }
    if (!formData.password && formData.password !== formData.confirmPassword) {
      validation.error = true;
      validation.message = 'Passwords did not be matched';
    }
    this.setState({
      validation,
    });
    if (validation.error) {
      return;
    }

    Service.User.ResetPassword(formData).then(response => {
      if (response) {
        this.setState({
          validation: {
            error: response.error,
            message: T(response.message),
          },
          disabled: !response.error,
        });
      }
    });
  }

  render() {
    const { validation, formData, disabled } = this.state;
    return (
      <>
        <div className="home-btn d-none d-sm-block">
          <Link to="/" className="text-dark"><i className="fas fa-home h2" /></Link>
        </div>

        <div className="wrapper-page">
          <div className="card overflow-hidden account-card mx-3">
            <div className="bg-dark p-4 text-white text-center position-relative">
              <h4 className="font-20 mb-4">{T('Reset Password')}</h4>
              <Link to="/login" className="logo logo-admin"><img src="/assets/images/partner-icon.svg" height="24" alt="logo" /></Link>
            </div>

            <div className="account-card-content">
              <ErrorMessage Validation={validation} />
              {validation.error
                ? ''
                : (
                  <div className="alert alert-success m-t-30" role="alert">
                    {validation.message}
                  </div>
                )}

              <div className="form-group">
                <label htmlFor="useremail">{T('Email')}</label>
                <input type="text" className="form-control" autoComplete="off" disabled={disabled} onChange={e => this.onChange('email', e.target.value)} value={formData.email} placeholder={T('Enter email')} />
              </div>
              <div className="form-group">
                <label htmlFor="password">{T('New Password')}</label>
                <input type="password" className="form-control" autoComplete="off" disabled={disabled} onChange={e => this.onChange('password', e.target.value)} value={formData.password} placeholder={T('Enter password')} />
              </div>
              <div className="form-group">
                <label htmlFor="confirmPassword">{T('Confirm Password')}</label>
                <input type="password" className="form-control" autoComplete="off" disabled={disabled} onChange={e => this.onChange('confirmPassword', e.target.value)} value={formData.confirmPassword} placeholder={T('Confirm password')} />
              </div>
              <div className="form-group row m-t-20 mb-0">
                <div className="col-12 text-right">
                  <button className="btn btn-dark w-md waves-effect waves-light" disabled={disabled} type="button" onClick={e => this.onReset(e)}>{T('Reset')}</button>
                </div>
              </div>
              <div className="form-group m-t-10 mb-0 row">
                <div className="col-12 m-t-20">
                  <Link to="/login">
                    <h6 className="text-primary">
                      <i className="fas fa-angle-double-left mr-1" />
                      <span>{T('Back to Login')}</span>
                    </h6>
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="m-t-40 text-center">
            <p>{`© ${new Date().getFullYear()} ${T('TRA Information Technologies')}`}</p>
          </div>
        </div>
      </>
    );
  }
}

ResetPassword.propTypes = {
  match: PropTypes.object.isRequired,
};
