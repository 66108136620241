import React, { Component } from 'react';
import { T } from 'react-translator-component';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { isArray } from 'util';
import { BreadCrumb, Card, CompanySelect, PublisherSelect } from '../../components';
import { Service } from '../../services/index';
import { Partner } from '../../config/AppConfig';

export class Dashboard extends Component {
  constructor(props) {
    super(props);

    const dashboardDate = Partner.utilities.dates.dashboard();

    this.state = {
      formData: {
        beginDate: dashboardDate.beginDate,
        endDate: dashboardDate.endDate,
        publisher: { value: -1, label: 'All' },
        companyId: -1,
        publisherId: -1,
        id: Partner.utilities.publisher(),
      },
      report: {
        leadCount: 0,
        customerCount: 0,
        firstTimeDeposit: 0,
        rebate: {
          total: 0,
          partner: 0,
          iBroker: 0,
        },
        lotVolume: {
          total: 0,
          partner: 0,
          iBroker: 0,
        },
        netDeposit: {
          total: 0,
          deposit: 0,
          withdrawal: 0,
        },
        active: {
          total: 0,
          partner: 0,
          iBroker: 0,
        },
        companyCount: 0,
      },
    };

    this.onDateChange = this.onDateChange.bind(this);
  }

  componentDidMount() {
    this.getReport();
  }

  onFilter() {
    this.getReport();
  }

  onDateChange(date) {
    const { formData } = this.state;

    if (date !== null && isArray(date)) {
      const [one, two] = date;
      formData.beginDate = one;
      formData.endDate = two;
      this.setState({
        formData,
      }, () => {
        localStorage.setItem('dashboardDate', JSON.stringify({ beginDate: one, endDate: two }));
      });
    }
  }

  getReport() {
    const { formData } = this.state;
    Service.Report.Dashboard(formData).then(response => {
      this.setState({
        report: response,
      });
    });
  }

  render() {
    const { formData, report } = this.state;
    return (
      <>
        <BreadCrumb Title="Home" Description={`${T('Welcome')} ${Partner.utilities.user().name}`} />
        <Card Title="Filter" Description="Lorem ipsum dolor sit amet">
          <div className="row mb-3">
            <div className="col-lg-12 pp-filter-wrapper">
              <div className="form-group mb-0">
                <label>{T('Company')}</label>
                <CompanySelect onChange={
                  option => this.setState(prevState => ({
                    formData: {
                      ...prevState.formData,
                      companyId: option.value,
                    },
                  }))
                }
                />
              </div>
              <div className="form-group mb-0">
                <label>{T('Publisher')}</label>
                <PublisherSelect
                  onChange={
                    option => this.setState(prevState => ({
                      formData: {
                        ...prevState.formData,
                        publisherId: option.value,
                      },
                    }))
                  }
                  company={formData.companyId}
                />
              </div>
              <div className="form-group mb-0">
                <label>{T('Date Range')}</label>
                <div>
                  <DateRangePicker
                    className="date-range-picker form-control"
                    onChange={this.onDateChange}
                    value={[formData.beginDate, formData.endDate]}
                    format="dd.MM.yyyy"
                  />
                </div>
              </div>
              <div className="form-group mb-0">
                <label>&nbsp;</label>
                <button type="button" className="btn btn-dark bg-dark btn-block" onClick={() => this.onFilter()}>{T('Filter Overview')}</button>
              </div>
            </div>
          </div>
        </Card>
        <div className="row">
          <div className="col-md-4">
            <Card Title="Rebate" Logo="fa-money-bill-alt">
              <div className="row">
                <div className="col-md-4">
                  <div className="text-center">
                    <p className="text-muted mb-3 text-success  font-18">{T('Total')}</p>
                    <h4 className="text-success ">
                      <span>$</span>
                      {report.rebate.total.toFixed(0).format('c')}
                    </h4>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="text-center">
                    <p className="text-muted mb-4">{T('My Customers')}</p>
                    <h6>
                      <span>$</span>
                      {report.rebate.partner.toFixed(0).format('c')}
                    </h6>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="text-center">
                    <p className="text-muted mb-4">{T('Sub IB')}</p>
                    <h6>
                      <span>$</span>
                      {report.rebate.iBroker.toFixed(0).format('c')}
                    </h6>
                  </div>
                </div>
              </div>
            </Card>
          </div>
          <div className="col-md-4">
            <Card Title="Lot Volume" Logo="fa-chart-area">
              <div className="row">
                <div className="col-md-4">
                  <div className="text-center">
                    <p className="text-muted mb-3 text-success  font-18">{T('Total')}</p>
                    <h4 className="text-success ">{report.lotVolume.total.toFixed(0).format('c', '.')}</h4>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="text-center">
                    <p className="text-muted mb-4">{T('My Customers')}</p>
                    <h6>{report.lotVolume.partner.toFixed(0).format('c', '.')}</h6>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="text-center">
                    <p className="text-muted mb-4">{T('Sub IB')}</p>
                    <h6>{report.lotVolume.iBroker.toFixed(0).format('c', '.')}</h6>
                  </div>
                </div>
              </div>
            </Card>
          </div>
          <div className="col-md-4">
            <Card Title="Net Deposit" Logo="fa-money-check-alt">
              <div className="row">
                <div className="col-md-4">
                  <div className="text-center">
                    <p className="text-muted mb-3 text-success  font-18">{T('Total')}</p>
                    <h4 className="text-success ">
                      <span>$</span>
                      {report.netDeposit.total.toFixed(0).format('c')}
                    </h4>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="text-center">
                    <p className="text-muted mb-4">{T('Deposit')}</p>
                    <h6>
                      <span>$</span>
                      {report.netDeposit.deposit.toFixed(0).format('c')}
                    </h6>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="text-center">
                    <p className="text-muted mb-4">{T('Withdrawal')}</p>
                    <h6>
                      <span>$</span>
                      {report.netDeposit.withdrawal.toFixed(0).format('c')}
                    </h6>
                  </div>
                </div>
              </div>
            </Card>
          </div>
          <div className="col-md-4">
            <Card Title="Active" Logo="fa-users">
              <div className="row">
                <div className="col-md-4">
                  <div className="text-center">
                    <p className="text-muted mb-3 text-success  font-18">{T('Total')}</p>
                    <h4 className="text-success ">{report.active.total}</h4>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="text-center">
                    <p className="text-muted mb-4">{T('Active Clients')}</p>
                    <h6>{report.active.partner}</h6>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="text-center">
                    <p className="text-muted mb-4">{T('Active IB')}</p>
                    <h6>{report.active.iBroker}</h6>
                  </div>
                </div>
              </div>
            </Card>
          </div>
          <div className="col-md-4">
            <Card Title="Other Information" Logo="fa-chart-pie">
              <div className="row">
                <div className="col-md-4">
                  <div className="text-center">
                    <p className="mb-3 text-warning font-16">{T('Number of Leads')}</p>
                    <h4 className="text-warning">{report.leadCount}</h4>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="text-center">
                    <p className="mb-3 text-danger font-16">{T('First Time Deposit')}</p>
                    <h4 className="text-danger">
                      <span>$</span>
                      {report.firstTimeDeposit.toFixed(0).format('c')}
                    </h4>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="text-center">
                    <p className="mb-3 text-primary font-16">{T('Company')}</p>
                    <h4 className="text-primary">{Partner.utilities.companyList().length}</h4>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </>
    );
  }
}
