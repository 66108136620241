import DataTableExtensions from 'react-data-table-component-extensions';
import DataTable from 'react-data-table-component';
import React, { Component } from 'react';
import Select from 'react-select';
import ValidForm from 'react-valid-form-component';
import { T } from 'react-translator-component';
import PropTypes from 'prop-types';
import { BreadCrumb, Card, Modal, ErrorMessage, Validation } from '../../../components';
import { DataTableColumns } from '../../../config/DataTableColumns';
import { Service } from '../../../services';
import { Partner } from '../../../config/AppConfig';
import { RebateTemplate } from './RebateTemplate';


export class Rebate extends Component {
  constructor(props) {
    super(props);

    const pageConfig = 'rebate';

    const { UserId } = props;

    this.state = {
      dataTable: {
        partner: {
          columns: DataTableColumns.admin[`${pageConfig}Partner`],
          rows: [],
          filteredRows: [],
        },
        iBroker: {
          columns: DataTableColumns.admin[`${pageConfig}IBroker`],
          rows: [],
        },
      },
      modal: false,
      url: Partner.service.admin[pageConfig],
      validation: {
        error: false,
        message: '',
        checkValid: false,
      },
      data: {
        rebateTypeSelected: { value: 'All', label: T('All') },
      },
      rebateType: null,
      isNew: false,
      filter: {
        rebateTypeSelected: { value: 'All', label: T('All') },
        companySelected: { value: -1, label: 'All' },
        companyId: -1,
        rebateType: 'All',
        publisherIdList: [],
        publisherIdSelectList: [],
        userId: UserId,
      },
      list: {
        company: [],
        status: [{ value: true, label: T('Active') }, { value: false, label: T('Passive') }],
        productGroup: new Map(),
        securityGroup: new Map(),
        userGroupType: new Map(),
        symbolSecurity: new Map(),
        symbol: new Map(),
        rebateType: [
          { value: 'All', label: T('All') },
          { value: 'Commission', label: T('Commission') },
          { value: 'CommissionSymbolSecurity', label: T('Commission Symbol Security') },
          { value: 'LotVolume', label: T('Lot Volume') },
          { value: 'Symbol', label: T('Symbol') },
          { value: 'SymbolSecurity', label: T('Symbol Security') },
        ],
        addNewRebateType: [
          { value: 'PartnerCommission', label: T('Partner Commission') },
          { value: 'PartnerCommissionSymbolSecurity', label: T('Partner Commission Symbol Security') },
          { value: 'PartnerLotVolume', label: T('Partner Lot Volume') },
          { value: 'PartnerSymbol', label: T('Partner Symbol') },
          { value: 'PartnerSymbolSecurity', label: T('Partner Symbol Security') },
          { value: 'IBrokerCommission', label: T('IBroker Commission') },
          { value: 'IBrokerCommissionSymbolSecurity', label: T('IBroker Commission Symbol Security') },
          { value: 'IBrokerLotVolume', label: T('IBroker Lot Volume') },
          { value: 'IBrokerSymbol', label: T('IBroker Symbol') },
          { value: 'IBrokerSymbolSecurity', label: T('IBroker Symbol Security') },
        ],
        rebateApprovalStatus: [
          { value: 1, label: 'Approved' },
          { value: 2, label: 'Pending' },
          { value: 3, label: 'Denied' },
        ],
        publisher: new Map(),
      },
    };

    this.onEdit = this.onEdit.bind(this);
    this.onChangeSelect = this.onChangeSelect.bind(this);
  }

  componentDidMount() {
    const { list } = this.state;
    list.publisher.set(1, []);
    list.publisher.set(2, []);
    this.setState({
      list,
    });
    this.getTable();
  }

  componentDidUpdate() {
    const { filter } = this.state;
    const { UserId } = this.props;

    if (filter.userId !== UserId) {
      filter.userId = UserId;
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        filter,
      });
    }
  }

  onChangeSelect(val, key) {
    const { data, isNew, list } = this.state;
    data[key] = val;
    if (key === 'companySelected') {
      data.companyId = val.value;
    }
    if (key === 'addRebateTypeSelected') {
      this.setState({
        rebateType: val.value,
      });
    }
    if (isNew === true && key === 'statusSelected') {
      data[key] = list.status.filter(f => f.value === true);
    }
    if (key === 'securityGroupSelected') {
      data.securityGroupId = val.value;
      data.symbolSecuritySelected = null;
    }
    if (key === 'productGroupSelected') {
      data.productGroupId = val.value;
      data.securityGroupSelected = null;
      data.symbolSecuritySelected = null;
    }

    this.setState({
      data,
    });
  }

  onFilterChange(val, key) {
    const { filter } = this.state;
    filter[key] = val;
    if (key === 'rebateTypeSelected') {
      filter.rebateType = val.value;
    }
    if (key === 'companySelected') {
      filter.companyId = val.value;
    }
    if (key === 'publisherSelected') {
      filter.publisherIdList = val !== null ? val.map(m => m.value) : [];
    }

    this.setState({
      filter,
    }, () => {
      this.filterData();
    });
  }

  onEdit(id, rebateType) {
    const { url } = this.state;

    this.setState({
      rebateType,
    });

    Service.Admin.Detail({ id }, url[rebateType.toLowerCase()]).then(response => {
      if (response && response.error === false) {
        let { data } = this.state;
        data = response.data;

        data = { ...response.data, ...this.fillDataForSelect(data, response.data, rebateType) };
        data.id = id;
        this.setState({
          data,
          modal: true,
          validation: {
            error: false,
            message: '',
            checkValid: false,
          },
        });
      }
    });
  }

  onSave(data, valid) {
    const { url, rebateType } = this.state;
    this.setState(prevState => ({
      validation: {
        ...prevState.validation,
        checkValid: true,
      },
    }));
    if (valid) {
      let postData = data || {};
      if (this.getDataForSave() === false) {
        return;
      }
      postData = { ...this.getDataForSave(), ...data };

      Service.Admin.Save(postData, url[rebateType.toLowerCase()]).then(response => {
        if (response) {
          if (response.error === false) {
            this.setState({
              modal: false,
              data: {},
            });
            this.getTable();
          } else {
            this.setState({
              validation: {
                error: response.error,
                message: response.message,
                checkValid: false,
              },
            });
          }
        }
      });
    }
  }

  onNew() {
    const { list } = this.state;
    this.setState({
      modal: true,
      data: {
        statusSelected: list.status.filter(f => f.value === true),
      },
      rebateType: 'New Rebate',
      isNew: true,
      validation: {
        error: false,
        message: '',
        checkValid: false,
      },
    });
  }

  getTable() {
    const { dataTable, url, list, filter } = this.state;
    const urlClone = { ...url };
    urlClone.list = `${url.list}/${filter.userId}`;
    Service.Admin.List(urlClone).then(response => {
      if (response && response.error === false) {
        dataTable.rows = response.data.data;
        dataTable.partner.rows = response.data.partnerData;

        dataTable.partner.filteredRows = dataTable.partner.rows;

        dataTable.iBroker.rows = response.data.iBrokerData;
        dataTable.iBroker.filteredRows = dataTable.iBroker.rows;

        list.company = response.data.common.company;

        response.data.common.productGroup.forEach(item => {
          list.productGroup.set(item.companyId, item.list);
        });

        response.data.common.securityGroup.forEach(item => {
          list.securityGroup.set(item.companyId, new Map());
          item.securityGroupList.forEach(securityGroup => {
            list.securityGroup.get(item.companyId).set(securityGroup.productGroupId, securityGroup.list);
          });
        });

        response.data.common.userGroupType.forEach(item => {
          list.userGroupType.set(item.companyId, item.list);
        });

        response.data.common.symbolSecurity.forEach(item => {
          list.symbolSecurity.set(item.companyId, new Map());
          item.symbolSecurityList.forEach(symbolSecurity => {
            list.symbolSecurity.get(item.companyId).set(symbolSecurity.securityGroupId, symbolSecurity.list);
          });
        });

        response.data.common.symbol.forEach(item => {
          list.symbol.set(item.companyId, item.list);
        });

        response.data.common.publisher.forEach(item => {
          list.publisher.set(item.companyId, item.list);
        });

        this.setState({
          dataTable,
        }, () => this.filterData());
      }
    });
  }

  getDataForSave() {
    const { data, rebateType } = this.state;
    if (!data.companySelected || !data.userGroupTypeSelected || !data.statusSelected || !data.rebateApprovalStatusSelected) {
      return false;
    }
    data.companyId = data.companySelected.value;
    data.userGroupTypeId = data.userGroupTypeSelected.value;
    data.status = data.statusSelected.value;
    data.rebateApprovalStatusId = data.rebateApprovalStatusSelected.value;

    if (rebateType.includes('Partner')) {
      if (!data.userPublisherIdSelected) {
        return false;
      }
      data.userPublisherId = data.userPublisherIdSelected.value;
    }

    if (rebateType.includes('IBroker')) {
      if (!data.masterIbSelected || !data.subIbSelected) {
        return false;
      }
      data.masterIb = data.masterIbSelected.value;
      data.subIb = data.subIbSelected.value;
    }

    const rebateFillForSave = {
      PartnerCommission: () => rebateFillForSave.Fill.ProductGroupAndSecurityGroup(),
      PartnerCommissionSymbolSecurity: () => rebateFillForSave.Fill.ProductGroupSecurityGroupAndSymbolSecurity(),
      PartnerLotVolume: () => rebateFillForSave.Fill.ProductGroupAndSecurityGroup(),
      PartnerSymbol: () => rebateFillForSave.Fill.Symbol(),
      PartnerSymbolSecurity: () => rebateFillForSave.Fill.ProductGroupSecurityGroupAndSymbolSecurity(),
      IBrokerCommission: () => rebateFillForSave.Fill.ProductGroupAndSecurityGroup(),
      IBrokerCommissionSymbolSecurity: () => rebateFillForSave.Fill.ProductGroupSecurityGroupAndSymbolSecurity(),
      IBrokerLotVolume: () => rebateFillForSave.Fill.ProductGroupAndSecurityGroup(),
      IBrokerSymbol: () => rebateFillForSave.Fill.Symbol(),
      IBrokerSymbolSecurity: () => rebateFillForSave.Fill.ProductGroupSecurityGroupAndSymbolSecurity(),
      Fill: {
        ProductGroupAndSecurityGroup: () => {
          if (!data.productGroupSelected || !data.securityGroupSelected) {
            return false;
          }
          data.productGroupId = data.productGroupSelected.value;
          data.securityGroupId = data.securityGroupSelected.value;
          return true;
        },
        ProductGroupSecurityGroupAndSymbolSecurity: () => {
          if (!data.productGroupSelected || !data.securityGroupSelected || !data.symbolSecuritySelected) {
            return false;
          }
          data.productGroupId = data.productGroupSelected.value;
          data.securityGroupId = data.securityGroupSelected.value;
          data.symbolSecurityId = data.symbolSecuritySelected.value;
          return true;
        },
        Symbol: () => {
          if (!data.symbolSelected) {
            return false;
          }
          data.symbolId = data.symbolSelected.value;
          return true;
        },
      },
    };

    const isValid = rebateFillForSave[rebateType]();

    return isValid ? data : isValid;
  }

  filterData() {
    const { dataTable, filter, list } = this.state;

    if (filter.rebateType !== 'All') {
      dataTable.partner.filteredRows = dataTable.partner.rows.filter(f => f.rebateType === `Partner${filter.rebateType}`);
    } else {
      dataTable.partner.filteredRows = dataTable.partner.rows;
    }
    if (filter.companyId !== -1) {
      dataTable.partner.filteredRows = dataTable.partner.filteredRows.filter(f => f.companyId === filter.companyId);
    }
    if (filter.publisherIdList.length > 0) {
      dataTable.partner.filteredRows = dataTable.partner.filteredRows.filter(f => filter.publisherIdList.includes(f.userPublisherId));
    }

    if (filter.rebateType !== 'All') {
      dataTable.iBroker.filteredRows = dataTable.iBroker.rows.filter(f => f.rebateType === `IBroker${filter.rebateType}`);
    } else {
      dataTable.iBroker.filteredRows = dataTable.iBroker.rows;
    }
    if (filter.companyId !== -1) {
      dataTable.iBroker.filteredRows = dataTable.iBroker.filteredRows.filter(f => f.companyId === filter.companyId);
      filter.publisherIdSelectList = list.publisher.get(filter.companyId);
    } else {
      filter.publisherIdSelectList = list.publisher.get(1).concat(list.publisher.get(2));
      if (filter.userId !== -1) {
        const userSelectObject = Partner.utilities.user().publisherList.filter(f => f.value === filter.userId);
        const userName = userSelectObject && userSelectObject.length > 0 && userSelectObject[0].label;
        filter.publisherIdSelectList = filter.publisherIdSelectList.filter(f => f.label.includes(userName));
      }
    }

    this.setState({
      dataTable,
      filter,
    });
  }

  fillDataForSelect(thisData, response, rebateType) {
    const data = thisData || {};
    const { list } = this.state;
    const [userGroupTypeSelected] = list.userGroupType.get(response.companyId).filter(f => f.value === response.userGroupTypeId);
    data.userGroupTypeSelected = userGroupTypeSelected;

    const [statusSelected] = list.status.filter(f => f.value === response.status);
    data.statusSelected = statusSelected;

    const [rebateApprovalStatusSelected] = list.rebateApprovalStatus.filter(f => f.value === response.rebateApprovalStatusId);
    data.rebateApprovalStatusSelected = rebateApprovalStatusSelected;

    const [companySelected] = list.company.filter(f => f.value === response.companyId);
    data.companySelected = companySelected;

    if (rebateType.includes('Partner')) {
      const [userPublisherIdSelected] = list.publisher.get(response.companyId).filter(f => f.value === response.userPublisherId);
      data.userPublisherIdSelected = userPublisherIdSelected;
    }
    if (rebateType.includes('IBroker')) {
      const [masterIbSelected] = list.publisher.get(response.companyId).filter(f => f.value === response.masterIb);
      data.masterIbSelected = masterIbSelected;

      const [subIbSelected] = list.publisher.get(response.companyId).filter(f => f.value === response.subIb);
      data.subIbSelected = subIbSelected;
    }

    let productGroupSelected = {};
    let securityGroupSelected = {};
    let symbolSecuritySelected = {};
    let symbol = {};

    const rebateTypeSelect = {
      PartnerCommission: () => {
        rebateTypeSelect.Select.ProductGroupAndSecurityGroup();
      },
      PartnerCommissionSymbolSecurity: () => {
        rebateTypeSelect.Select.ProductGroupSecurityGroupAndSymbolSecurity();
      },
      PartnerLotVolume: () => {
        rebateTypeSelect.Select.ProductGroupAndSecurityGroup();
      },
      PartnerSymbol: () => {
        rebateTypeSelect.Select.Symbol();
      },
      PartnerSymbolSecurity: () => {
        rebateTypeSelect.Select.ProductGroupSecurityGroupAndSymbolSecurity();
      },
      IBrokerCommission: () => {
        rebateTypeSelect.Select.ProductGroupAndSecurityGroup();
      },
      IBrokerCommissionSymbolSecurity: () => {
        rebateTypeSelect.Select.ProductGroupSecurityGroupAndSymbolSecurity();
      },
      IBrokerLotVolume: () => {
        rebateTypeSelect.Select.ProductGroupAndSecurityGroup();
      },
      IBrokerSymbol: () => {
        rebateTypeSelect.Select.Symbol();
      },
      IBrokerSymbolSecurity: () => {
        rebateTypeSelect.Select.ProductGroupSecurityGroupAndSymbolSecurity();
      },
      Select: {
        ProductGroup: () => {
          [productGroupSelected] = list.productGroup.get(response.companyId).filter(f => f.value === response.productGroupId);
          data.productGroupSelected = productGroupSelected;
        },
        SecurityGroup: () => {
          [securityGroupSelected] = list.securityGroup.get(response.companyId).get(response.productGroupId).filter(f => f.value === response.securityGroupId);
          data.securityGroupSelected = securityGroupSelected;
        },
        SymbolSecurity: () => {
          [symbolSecuritySelected] = list.symbolSecurity.get(response.companyId).get(response.securityGroupId).filter(f => f.value === response.symbolSecurityId);
          data.symbolSecuritySelected = symbolSecuritySelected;
        },
        Symbol: () => {
          [symbol] = list.symbol.get(response.companyId).filter(f => f.value === response.symbolId);
          data.symbolSelected = symbol;
        },
        ProductGroupAndSecurityGroup: () => {
          rebateTypeSelect.Select.ProductGroup();
          rebateTypeSelect.Select.SecurityGroup();
        },
        ProductGroupSecurityGroupAndSymbolSecurity: () => {
          rebateTypeSelect.Select.ProductGroup();
          rebateTypeSelect.Select.SecurityGroup();
          rebateTypeSelect.Select.SymbolSecurity();
        },
      },
    };
    rebateTypeSelect[rebateType]();
    return data;
  }

  render() {
    const { dataTable, data, validation, modal, rebateType, isNew, filter, list } = this.state;
    const { Detail } = this.props;

    let Hassan = RebateTemplate[rebateType];
    if (typeof rebateType === 'undefined' || rebateType === null || rebateType === 'New Rebate') {
      Hassan = () => (<></>);
    }

    return (
      <>
        {!Detail && <BreadCrumb Title="User Group" Description="Lorem ipsum dolor sit amet" />}
        <div className="row">
          <div className="col-md-12">
            <Card Title="Partner Rebates" Description="Lorem ipsum dolor sit amet">
              <div className="row">
                <div className="col-md-12">
                  <div className="row">
                    <label className="col-md-2 col-form-label">
                      {T('Company')}
                    </label>
                    <label className="col-md-2 col-form-label">
                      {T('Publisher')}
                    </label>
                    <label className="col-md-2 col-form-label">
                      {T('Rebate Type')}
                    </label>
                  </div>
                  <div className="row">
                    <div className="col-md-2">
                      <Select
                        value={filter.companySelected}
                        options={[{ value: -1, label: 'All' }].concat(list.company)}
                        onChange={option => this.onFilterChange(option, 'companySelected')}
                        isMulti={false}
                      />
                    </div>
                    <div className="col-md-2">
                      <Select
                        value={filter.publisherSelected}
                        options={filter.publisherIdSelectList}
                        onChange={option => this.onFilterChange(option, 'publisherSelected')}
                        isMulti
                      />
                    </div>
                    <div className="col-md-2">
                      <Select
                        value={filter.rebateTypeSelected}
                        options={list.rebateType}
                        onChange={option => this.onFilterChange(option, 'rebateTypeSelected')}
                        isMulti={false}
                      />
                    </div>
                  </div>

                </div>

                <div className="col-md-12">
                  <button type="button" className="btn btn-info position-absolute add-new-button tabless" onClick={() => this.onNew()}>
                    <i className="fas fa-plus  mr-2" />
                    {T('Add New')}
                  </button>
                  <DataTableExtensions columns={dataTable.partner.columns(this.onEdit)} data={dataTable.partner.filteredRows} export={false} print={false}>
                    <DataTable
                      className="tbl-admin-rebate-partner"
                      noHeader
                      defaultSortField="id"
                      defaultSortAsc={false}
                      pagination
                      highlightOnHover
                    />
                  </DataTableExtensions>
                </div>
              </div>
            </Card>
          </div>
          <div className="col-md-12">
            <Card Title="IBroker Rebates" Description="Lorem ipsum dolor sit amet">
              <div className="row">
                <div className="col-md-12">
                  <div className="row">
                    <label className="col-md-2 col-form-label">
                      {T('Company')}
                    </label>
                    <label className="col-md-2 col-form-label">
                      {T('Publisher')}
                    </label>
                    <label className="col-md-2 col-form-label">
                      {T('Rebate Type')}
                    </label>
                  </div>
                  <div className="row">
                    <div className="col-md-2">
                      <Select
                        value={filter.companySelected}
                        options={[{ value: -1, label: 'All' }].concat(list.company)}
                        onChange={option => this.onFilterChange(option, 'companySelected')}
                        isMulti={false}
                      />
                    </div>
                    <div className="col-md-2">
                      <Select
                        value={filter.publisherSelected}
                        options={filter.publisherIdSelectList}
                        onChange={option => this.onFilterChange(option, 'publisherSelected')}
                        isMulti
                      />
                    </div>
                    <div className="col-md-2">
                      <Select
                        value={filter.rebateTypeSelected}
                        options={list.rebateType}
                        onChange={option => this.onFilterChange(option, 'rebateTypeSelected')}
                        isMulti={false}
                      />
                    </div>
                  </div>

                </div>

                <div className="col-md-12">
                  <button type="button" className="btn btn-info position-absolute add-new-button tabless" onClick={() => this.onNew()}>
                    <i className="fas fa-plus  mr-2" />
                    {T('Add New')}
                  </button>
                  <DataTableExtensions columns={dataTable.iBroker.columns(this.onEdit)} data={dataTable.iBroker.filteredRows} export={false} print={false}>
                    <DataTable
                      className="tbl-admin-rebate-ibroker"
                      noHeader
                      defaultSortField="id"
                      defaultSortAsc={false}
                      pagination
                      highlightOnHover
                    />
                  </DataTableExtensions>
                </div>
              </div>
            </Card>
          </div>
        </div>
        <Modal Title={`${T('Edit')} ${rebateType}`} Show={modal} onClose={() => this.setState({ modal: false, data: {}, isNew: false })}>
          <div className="row mt-1 mb-1">
            <p className="text-muted mb-2 ml-1 col-md-12 font-12">{T(rebateType)}</p>
            <div className="col-md-12">
              <ValidForm className="form-horizontal m-t-10" nosubmit novalid onSubmit={(form, formData, valid) => this.onSave(formData, valid)} data={data}>
                <div className="form-group">
                  <div className="row">
                    <ErrorMessage Validation={validation} />
                  </div>
                </div>
                {isNew ?
                  (
                    <div className="form-group row ml-1 mr-1">
                      <label htmlFor="login" className="col-sm-4 col-form-label">{T('Rebate Type')}</label>
                      <div className="col-sm-8">
                        <Select
                          value={data.addRebateTypeSelected}
                          options={list.addNewRebateType}
                          onChange={option => this.onChangeSelect(option, 'addRebateTypeSelected')}
                          isMulti={false}
                        />
                        <Validation Value={data.addRebateTypeSelected} Check={validation.checkValid} />
                      </div>
                    </div>
                  )
                  : ''
                }
                <Hassan list={list} data={data} onChangeSelect={this.onChangeSelect} validation={validation} />
                <div className="form-group row m-t-20 mr-1">
                  <div className="col-sm-12 text-right">
                    <button type="submit" className="btn btn-success w-md waves-effect waves-light">{T('Save')}</button>
                  </div>
                </div>
              </ValidForm>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

Rebate.propTypes = {
  Detail: PropTypes.bool,
  UserId: PropTypes.number,
};

Rebate.defaultProps = {
  Detail: undefined,
  UserId: -1,
};
